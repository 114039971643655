import React, {
  Component,
  Suspense,
  useContext,
  useState,
  useEffect,
  lazy,
} from "react";

import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelContent,
} from "../panel/panel.jsx";
import {
  hideModal,
  isLoading,
  showModal,
  isEdit,
  setFocus,
  simpanDataTmp,
} from "../../actions/datamaster_action";
import { Field, reduxForm, formValueSelector, reset, change } from "redux-form";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import {
  ToastNotification,
  NotifSucces,
  NotifError,
  NotifInfo,
  NotificationConfirm,
  NotifTagId,
  NotifCustome,
  NotifPlaform,
  NotifTYpe,
} from "./notification.jsx";
import {
  deleteData,
  deleteImage,
  getDataParams,
  checkTimeout,
  getImage,
  postImage,
  postData,
  putDataNoTImeOut,
  putData,
  getData,
  getDataNoTimeOut,
  postDataNoTImeOut,
  postUrlOnlineStore,
  postDataNoTImeOutPusat,
  getDataParamsPusat,
  sendWhatsapp,
  uploadFileShopee,
} from "../../config/axios.jsx";
import Tabel from "./tabel.jsx";
import ModalGlobal from "./ModalGlobal.jsx";
import {
  ReanderField,
  HiidenFiled,
  ReanderSelect,
  SkeletonLoading,
  InputDate,
  ReanderCheckBox,
  ReanderSelect2,
  ReanderTextArea,
  RenderFieldGroup,
  inputGroup,
} from "./field";
import { v4 as uuidv4 } from "uuid";
import {
  ErrorHandling,
  resizeFile,
  convertBase64,
  pembulatanBeratV1,
  dataURLtoFile,
  getItem,
  getUserdata,
  setItem,
  convertDate,
  formatDate,
  getTglSystem,
  getToday,
  removeItem,
  formatGram,
  JSONToCSVConvertor,
  convertDateTime,
  pembulatanPenjualan,
  pembulatanPenjualan500,
  convertDateNota,
  customePembulatan,
  formaterBerat,
  pembulatanBeratV2,
  findModule,
  isPos,
  findModuleText,
  removeDuplicateObject,
  capitalize,
  LoadingContent,
  TextFile,
  pembulatan,
  pembulatanBeratV3,
  setTitle,
  pembulatanBeratV4,
  sendInvoiceWaGrouped,
  sendInvoiceSingle,
  downloadfileImg,
  pembulatanBeratV6,
  isNumeric,
  addOneDay,
  setCookie,
  removeCookie,
  getCookie,
  fomaterBeratNew,
  shortByReport,
  convertXLSXToJson,
  resizeFileCustom,
  // infoMarketPlace
} from "./function.jsx";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import imgfound from "../../assets/img/temp.jpg";
import imgNotfound from "../../assets/image-not-found.png";
import bgputih from "../../assets/img/bgputih.jpg";
import BootstrapTable from "react-bootstrap-table-next";
import Camera from "react-html5-camera-photo";
import moment from "moment-timezone";
import { createNumberMask } from "redux-form-input-masks";
import Loading from "react-fullscreen-loading";
import Stepper from "react-stepper-horizontal";
import CustomeInputEditFiled from "./customeInputEditFiled.jsx";
import {
  ListKodeTokoBerkah,
  ListKodeTokoDamay,
  ListKodeTokoPantes,
  ListKodeTokoSambas,
  ListKodeTokoUtami,
  ListSambasEyang,
  ListTokoBares,
  ListTokoLaris,
  ListTokoMacan,
} from "./ListKodeToko.jsx";

import notFound from "../../assets/img/temp.jpg";
import userfound from "../../assets/img/temp.jpg";
import bgHitam from "../../assets/img/bghitam.png";
import { io } from "socket.io-client";
import Skeleton from "react-loading-skeleton";
import { Redirect } from "react-router-dom";
import { PageSettings } from "../../config/page-settings.js";
import { Modal, ModalBody, Progress } from "reactstrap";
import KelompokHarga from "./KelompokHarga.jsx";
import NotaSlipGanerate from "./NotaSlipGanerate.jsx";
import TabelPagenation from "./TabelPagenation.jsx";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectorMaster, selectorUtility } from "../../selector";
import FilterLaporanTgl from "./FilterLaporanTgl.jsx";
import CabangSelector from "../../components/pusat/cabang_selector.jsx";
import ModalOtorisasi from "./ModalOtorisasi.jsx";
import Button from "./button";
import ValidBy from "./ValidBy.jsx";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import BackButtonPos from "../../components/pos_property/BackButton.jsx";
import { Route, withRouter } from "react-router-dom";
import routes from "../../config/page-route.jsx";
import ScrollAnimation from "react-animate-on-scroll";
import metadata from "../../metadata.json";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import ModalLocal from "./ModalLocal.jsx";
import Progresbar from "./Progresbar";
import { Accordion } from "./Accordion";
import * as XLSX from "xlsx";
import FingerPrintComponent from "./FingerPrintComponent.jsx";
import FormOtorisasiFingerDanManual from "./FormOtorisasiFingerDanManual.jsx";
import { doDecrypt, doEncrypt } from "./encrypt.js";
import lang from "./lang.jsx";
import ShortBy from "./ShortBy.jsx";
const currencyMask = createNumberMask({
  prefix: "Rp. ",
  locale: "kr-KO",
});
export * from "./Module.jsx";

let globalConfigEnv = "";
let globalConfig = "";
if (process.env.REACT_APP_IS_ENCRYPT === "true") {
  globalConfigEnv = doDecrypt(window.globalConfig);
  globalConfig = JSON?.parse(globalConfigEnv?.replaceAll("~", ""));
} else {
  // globalConfigEnv = window.globalConfig;
  globalConfig = window.globalConfig;
}
// let globalConfigEnv = doDecrypt(window.globalConfig);
// console.log(globalConfig);

const server = globalConfig.REACT_APP_BACKEND_URL;
const url = globalConfig.REACT_APP_URL;
const isPusat = globalConfig.REACT_APP_TYPE === "PUSAT" ? true : false;
const serverWa = globalConfig.REACT_APP_BACKEND_WA;
const serverGrosir = globalConfig.REACT_APP_BACKEND_SERVER_GROSIR;
const serverOnlineStore = globalConfig.REACT_APP_BACKEND_ONLINE_STORE;
const firebasemame = globalConfig.REACT_APP_FIREBASE_NAME;
const ModePusat = globalConfig.REACT_APP_TYPE;
const appVersion = globalConfig.REACT_APP_VERSION;
const isencrytENv = globalConfig.REACT_APP_IS_ENCRYPT;
const AuthManual = globalConfig.REACT_APP_AUTH_TYPE;
const DomainGrosir = globalConfig.REACT_APP_DOMAIN_GROSIR;
const kodeTokoPusat = globalConfig.REACT_APP_KODE_TOKO_PST || "PST";
const AppMode = globalConfig.REACT_APP_TYPE;

export {
  resizeFileCustom,
  ListKodeTokoDamay,
  uploadFileShopee,
  sendWhatsapp,
  doEncrypt,
  ListTokoLaris,
  ListKodeTokoBerkah,
  ShortBy,
  shortByReport,
  fomaterBeratNew,
  convertXLSXToJson,
  doDecrypt,
  NotifTYpe,
  getCookie,
  removeCookie,
  setCookie,
  lang,
  kodeTokoPusat,
  AppMode,
  serverWa,
  serverGrosir,
  serverOnlineStore,
  firebasemame,
  ModePusat,
  appVersion,
  isencrytENv,
  AuthManual,
  DomainGrosir,
  FormOtorisasiFingerDanManual,
  FingerPrintComponent,
  isNumeric,
  pembulatanBeratV6,
  isPusat,
  Accordion,
  Progresbar,
  ModalLocal,
  imgNotfound,
  downloadfileImg,
  sendInvoiceSingle,
  sendInvoiceWaGrouped,
  postDataNoTImeOutPusat,
  pembulatanBeratV4,
  BrowserRouter,
  getDataParamsPusat,
  ReactDOM,
  createStore,
  addOneDay,
  applyMiddleware,
  compose,
  Provider,
  thunk,
  ScrollAnimation,
  metadata,
  routes,
  server,
  Route,
  withRouter,
  url,
  setTitle,
  pembulatanBeratV3,
  bgHitam,
  bgputih,
  BackButtonPos,
  ListSambasEyang,
  XLSX,
  ListKodeTokoUtami,
  ListTokoBares,
  inputGroup,
  ListTokoMacan,
  ValidBy,
  NotifPlaform,
  ReactHTMLTableToExcel,
  NotifCustome,
  Button,
  pembulatan,
  RenderFieldGroup,
  ModalOtorisasi,
  NotifTagId,
  TextFile,
  selectorUtility,
  CabangSelector,
  LoadingContent,
  FilterLaporanTgl,
  selectorMaster,
  NotificationConfirm,
  capitalize,
  NotaSlipGanerate,
  KelompokHarga,
  useSelector,
  ReanderTextArea,
  useDispatch,
  TabelPagenation,
  PageSettings,
  Suspense,
  io,
  useContext,
  Modal,
  ModalBody,
  Progress,
  Skeleton,
  Redirect,
  removeDuplicateObject,
  findModuleText,
  isPos,
  findModule,
  pembulatanBeratV2,
  postUrlOnlineStore,
  notFound,
  userfound,
  ReanderSelect2,
  formaterBerat,
  resizeFile,
  customePembulatan,
  convertDateNota,
  pembulatanPenjualan500,
  pembulatanPenjualan,
  convertDateTime,
  ListKodeTokoSambas,
  CustomeInputEditFiled,
  ListKodeTokoPantes,
  currencyMask,
  React,
  pembulatanBeratV1,
  Stepper,
  change,
  formatGram,
  NotifSucces,
  NotifError,
  NotifInfo,
  deleteImage,
  setFocus,
  getDataParams,
  getToday,
  getImage,
  postImage,
  lazy,
  removeItem,
  convertDate,
  getUserdata,
  dataURLtoFile,
  checkTimeout,
  Loading,
  JSONToCSVConvertor,
  PanelBody,
  putDataNoTImeOut,
  getDataNoTimeOut,
  postDataNoTImeOut,
  PanelContent,
  // DualListBox,
  formValueSelector,
  ReanderCheckBox,
  InputDate,
  getTglSystem,
  imgfound,
  formatDate,
  moment,
  createNumberMask,
  Camera,
  convertBase64,
  ToolkitProvider,
  SkeletonLoading,
  BootstrapTable,
  useState,
  useEffect,
  ErrorHandling,
  uuidv4,
  setItem,
  getItem,
  isLoading,
  simpanDataTmp,
  showModal,
  isEdit,
  ToastNotification,
  Swal,
  reset,
  Link,
  PanelHeader,
  Component,
  Panel,
  ModalGlobal,
  Tabel,
  connect,
  Field,
  hideModal,
  reduxForm,
  getData,
  HiidenFiled,
  ReanderField,
  ReanderSelect,
  deleteData,
  postData,
  putData,
};
