import jsPDF from "jspdf";
import "jspdf-autotable";
import JsBarcode from "jsbarcode";
// import { Stepper } from "../../../../components";

let jml_nota = 2;
// let jml_nota = 2;
let stepFirst = [];

const NotaPenjualanBerlianLrs = (data = "", kode_baki = "") => {
  const doc = new jsPDF("p", "mm", [297, 210]);
  for (let nomer = 0; nomer < jml_nota; nomer++) {
    stepFirst.push(nomer);

    let tableRows = [];
    let tableColumn = [];
    let finalY = 60;

    doc.setProperties({
      title: "NOTA PENJUALAN BERLIAN",
    });
    doc.setFontSize(8);

    // eslint-disable-next-line
    data.forEach((list, index) => {
      let nama = list.nama_customer || "-";
      doc.text(nama?.slice(0, 23), 50, 15);

      //Alamat
      let alamat = list.alamat_customer || "-";
      doc.text(alamat.slice(0, 20), 50, 19);
      if (alamat.length > 20) {
        doc.text(alamat.slice(21, 60), 50, 23);
      }
      if (alamat.length > 40 && alamat.length < 80) {
        doc.text(alamat.slice(60, 80), 50, 27);
      }

      // Data Invoice

      doc.setFontSize(16);
      doc.text("INVOICE", 155, 35);
      let noFak = list.no_faktur_jual || "-";
      doc.setFontSize(10);
      doc.text(noFak.slice(0, 23), 155, 43);
      doc.text(list.tgl_system || "-", 155, 49);

      doc.text(list.tgl_system || "-", 50, 55);
      doc.text(list.jam || "-", 80, 55);
      doc.text("Sales By :", 100, 55);
      doc.text(list.kode_sales, 118, 55);
      doc.text("Dibuat Oleh :", 138, 55);
      doc.text(list.input_by || "-", 160, 55);

      // BwipJs.toCanvas("itf", {
      //   bcid: "code128", // Barcode type
      //   text: list.kode_barcode, // Text to encode
      //   scale: 4, // 3x scaling factor
      //   height: 15, // Bar height, in millimeters
      //   includetext: false, // Show human-readable text
      //   textxalign: "center", // Always good to set this
      // });
      // const imgbarcode = document.getElementById("itf");
      // var dataURL = imgbarcode.toDataURL();
      const canvas = document.createElement("canvas");
      canvas.id = "barcodeCanvas"; // Unique identifier
      JsBarcode(canvas, list.kode_barcode, {
        format: "CODE128",
        displayValue: false,
        width: 2,
        height: 40,
      });
      doc.addImage(canvas, "jpg", 150, 5, 40, 20, parseInt(180 * -1));

      console.log(nomer, "nomer");
      if (stepFirst.length > 1) {
        doc.setFontSize(70);
        doc.setTextColor(230, 230, 230);
        doc.text("COPY", 260 * 0.2645833333, 300 * 0.2645833333);
      }

      doc.setTextColor(0, 0, 0);
      doc.setFontSize(list.font_size || 10);

      tableColumn = [
        [
          {
            content: `No`,
            styles: {
              cellWidth: 10,
              halign: "center",
              valign: "middle",
            },
          },
          {
            content: `Code`,
            styles: {
              halign: "center",
            },
          },
          {
            content: `Qty`,
          },
          {
            content: `Keterangan`,
            styles: {
              halign: "center",
            },
          },
          {
            content: `Karat%`,
          },
          {
            content: `Gram`,
            styles: {
              halign: "center",
            },
          },
          {
            content: `Ongkos`,
            styles: {
              halign: "center",
            },
          },
          {
            content: `Harga`,
            styles: {
              halign: "center",
            },
          },
          {
            content: `Picture`,
            styles: {
              halign: "center",
            },
          },
        ],
      ];

      // let posisi = 35;
      // data.forEach((element) => {
      let row = [
        {
          content: `${"1"}\n`,
          styles: {
            cellWidth: 10,
            halign: "center",
            valign: "middle",
          },
        },

        {
          content: `${list.kode_barcode || "-"}\n`,
          styles: {
            halign: "center",
            valign: "middle",
          },
        },
        {
          content: `${list.qty || "-"}\n`,
          styles: {
            halign: "center",
            valign: "middle",
          },
        },
        {
          content: `${list.nama_barang || "-"}\n${(
            list.keterangan_berlian || "-"
          ).toUpperCase()}\n ${list.color || "-"}\n${
            list.barang.kode_intern || "-"
          }\n${list.data_berlian || "-"}\n`,
          styles: {
            halign: "left",
          },
        },
        {
          content: `${list.barang.kadar_cetak || "-"}\n`,
          styles: {
            halign: "center",
            valign: "middle",
          },
        },
        {
          content: `${list.berat.toFixed(3) || "-"} Gr \n`,
          styles: {
            halign: "center",
            valign: "middle",
          },
        },
        {
          content: `${list.ongkos.toLocaleString("kr-ko") || "-"} \n`,
          styles: {
            halign: "center",
            valign: "middle",
          },
        },
        {
          content: `${list.harga_jual.toLocaleString("kr-ko") || "-"} \n`,
          styles: {
            halign: "center",
            valign: "middle",
          },
        },
        {
          content: "",
          styles: {
            cellWidth: 50,
            halign: "center",
            valign: "middle",
          },
        },
      ];
      tableRows.push(row);

      // posisi = posisi + 40;
      doc.autoTable({
        head: tableColumn,
        body: tableRows,
        didDrawCell: (row) => {
          var posisix = row.cell.x;
          var posisiy = row.cell.y;
          if (row.cell.section === "body" && row.column.index === 8) {
            if (list.gambar !== undefined) {
              doc.addImage(list.gambar, posisix + 15, posisiy + 5, 20, 20);
            }
          }
        },
        // foot: footRows,
        startY: finalY,
        theme: "plain",
        rowPageBreak: "avoid",
        // pageBreak: "avoid",
        margin: { top: 15 },

        footStyles: {
          fontSize: 8,
        },
        bodyStyles: {
          fontSize: 8,
          lineWidth: 0.02,
          lineColor: "#000",
        },
        headStyles: {
          fontSize: 8,
          lineWidth: 0.02,
          lineColor: "#000",
          // textColor: "#000",
          // fillColor: "#E8E5E5",
        },
      });
      finalY = doc.autoTableEndPosY() + 3;
      tableRows = [];
      tableRows = [];
      if (nomer < jml_nota - 1) {
        doc.addPage();
      }
    });
  }

  stepFirst = [];

  //Nama Customer

  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  //   if (data.notajual.length > 10) {
  //     doc.save(`Cetak Faktur Penjualan.pdf`);
  //   } else {
  var string = doc.output("datauristring");

  var x = window.open();
  x.document.open();
  x.document.write(
    "<html><head><title>Cetak Faktur Penjualan Berlian</title></head><body style='margin:0 !important'><embed width='100%' height='100%'  src=" +
      string +
      "></embed></body></html>"
  );
  //   }
};

export default NotaPenjualanBerlianLrs;
