import {
  findModule,
  getTglSystem,
  getUserdata,
} from "../components/helpers/function";
import { postData, ToastNotification } from "../components/helpers/library";
import {
  getData,
  getDataNoTimeOut,
  getDataParams,
  postDataNoTImeOut,
} from "../config/axios";
import { FormModal } from "./databarang_action";

const aktifTglSebelumnyaByOwner = findModule(
  "MODULE_AKTIF_TGL_SEBELUMNYA_BY_OWNER"
);
const aktifTglSebelumnyaBySPV = findModule(
  "MODULE_AKTIF_TGL_SEBELUMNYA_BY_SPV"
);

export const GET_UANG_BANK = "GET_UANG_BANK";
export const HIDE_MODAL = "HIDE_MODAL";
export const SHOW_MODAL = "SHOW_MODAL";
export const EDIT_DATA_BANK = "EDIT_DATA_BANK";
export const GET_DATA_PROVINSI = "GET_DATA_PROVINSI";
export const EDIT_DATA_PROVINSI = "EDIT_DATA_PROVINSI";
export const SHOW_MODAL_KATEGORI = "SHOW_MODAL_KATEGORI";
export const EDIT_KATEGORI = "EDIT_KATEGORI";
export const ISLOADING = "ISLOADING";
export const ISEDIT = "ISEDIT";
//Variable JENIS
export const SHOW_MODAL_JENIS = "SHOW_MODAL_JENIS";
export const SHOW_MODAL_KOTA = "SHOW_MODAL_KOTA";
export const SHOW_MODAL_KECAMATAN = "SHOW_MODAL_KECAMATAN";
export const GET_DATA_JENIS = "GET_DATA_JENIS";
export const GET_DATA_KADAR_EOD = "GET_DATA_KADAR_EOD";
export const GET_DATA_KOTA = "GET_DATA_KOTA";
export const GET_DATA_KECAMATAN = "GET_DATA_KECAMATAN";

export const GET_DATA_SUPPLIER = "GET_DATA_SUPPLIER";

//Variable Kode Transakso
export const SHOW_MODAL_KODE_TRANSAKSI = "SHOW_MODAL_KODE_TRANSAKSI";
export const GET_KODE_TRANSAKSI = "GET_KODE_TRANSAKSI";

//Variable Gudang
export const SHOW_MODAL_GUDANG = "SHOW_MODAL_GUDANG";
export const GET_DATA_GUDANG = "GET_DATA_GUDANG";
export const GET_DATA_JENIS_BERLIAN = "GET_DATA_JENIS_BERLIAN";

//Variable Baki
export const SHOW_MODAL_BAKI = "SHOW_MODAL_BAKI";
export const GET_DATA_BAKI = "GET_DATA_BAKI";

//Variable Sales
export const SHOW_MODAL_SALES = "SHOW_MODAL_SALES";
export const GET_DATA_SALES = "GET_DATA_SALES";

//Variable Supplier
export const SHOW_MODAL_SUPPLIER_BERLIAN = "SHOW_MODAL_SUPPLIER_BERLIAN";
export const GET_DATA_SUPPLIER_BERLIAN = "GET_DATA_SUPPLIER_BERLIAN";

//Variable Data Tukang
export const SHOW_MODAL_TUKANG = "SHOW_MODAL_TUKANG";
export const GET_DATA_TUKANG = "GET_DATA_TUKANG";

//Variavle Data Kondisi Barang
export const SHOW_MODALKONDISI_BARANG = "SHOW_MODALKONDISI_BARANG";
export const GET_DATA_KONDISI_BARANG = "GET_DATA_KONDISI_BARANG";

//Variable Kondisi Pembelian
export const GET_KONDISI_PEMBELIAN = "GET_KONDISI_PEMBELIAN";
export const SHOW_MODOAL_KONDISI_PEMBELIAN = "SHOW_MODOAL_KONDISI_PEMBELIAN";

//Variable Kondisi Pinjaman
export const GET_KONDISI_PINJAMAN = "GET_KONDISI_PINJAMAN";
export const SHOW_MODAL_KONDISI_PINJAMAN = "SHOW_MODAL_KONDISI_PINJAMAN";

export const SHOW_MODAL_DATA_HADIAH = "SHOW_MODAL_DATA_HADIAH";
export const SHOW_MODAL_RUGI_NOTA = "SHOW_MODAL_RUGI_NOTA";

//Variable Kadar
export const SHOW_MODAL_KADAR = "SHOW_MODAL_KADAR";
export const GET_DATA_KADAR = "GET_DATA_KADAR";

//Variable Group
export const SHOW_MODAL_GROUP = "SHOW_MODAL_GROUP";
export const GET_DATA_GROUP = "GET_DATA_GROUP";
export const AKTIF_TGL = "AKTIF_TGL";

export const GET_BANDROL = "GET_BANDROL";
export const GET_PARAM_POINT_MEMBER = "GET_PARAM_POINT_MEMBER";
export const SHOW_MODAL_BANDROL = "SHOW_MODAL_BANDROL";
export const GET_PARAMETER_POINT = "GET_PARAMETER_POINT";
export const SHOW_MODAL_PARAMETER_POINT = "SHOW_MODAL_PARAMETER_POINT";

export const SHOW_MODAL_PARAMETER_HARGA_EMAS =
  "SHOW_MODAL_PARAMETER_HARGA_EMAS";
export const GET_PARAMETER_HARGA_EMAS = "GET_PARAMETER_HARGA_EMAS";
export const GET_DATA_HADIAH = "GET_DATA_HADIAH";
export const GET_PARAMETER_RUGI_NOTA = "GET_PARAMETER_RUGI_NOTA";

export const SHOW_MODAL_MARKETPLACE = "SHOW_MODAL_MARKETPLACE";
export const GET_DATA_MARKETPLCAE = "GET_DATA_MARKETPLCAE";
export const GET_DATA_KAS = "GET_DATA_KAS";

export const SET_NO = "SET_NO";

export const GET_DATA_CLARITY = "GET_DATA_CLARITY";
export const GET_DATA_SHAPE = "GET_DATA_SHAPE";
export const GET_DATA_BATU_COLOR = "GET_DATA_BATU_COLOR";
export const GET_DATA_FLU = "GET_DATA_FLU";
export const GET_DATA_SYEMETRY = "GET_DATA_SYEMETRY";
export const GET_DATA_CUTGRAD = "GET_DATA_CUTGRAD";
export const GET_DATA_POLISH = "GET_DATA_POLISH";
export const GET_BANK = "GET_BANK";
export const GET_PARAM_HARGA_BELI = "GET_PARAM_HARGA_BELI";
export const GET_REKENING = "GET_REKENING";
export const SET_FOCUS = "SET_FOCUS";
export const SHOW_DATA_MODAL = "SHOW_DATA_MODAL";
export const GET_JENIS_BATU = "GET_JENIS_BATU";
export const GET_DATA_PENJUALAN_PERBULAN = "GET_DATA_PENJUALAN_PERBULAN";
export const GET_DATA_PEMBELIAN_PERBULAN = "GET_DATA_PEMBELIAN_PERBULAN";
export const GET_PENJUALAN_TODAY = "GET_PENJUALAN_TODAY";
export const GET_PEMBELIAN_TODAY = "GET_PEMBELIAN_TODAY";
export const GET_PESANAN_TODAY = "GET_PESANAN_TODAY";
export const GET_SERVICE_TODAY = "GET_SERVICE_TODAY";
export const GET_MASTER_TOKO = "GET_MASTER_TOKO";
export const SHOW_MODAL_MASTER_TOKO = "SHOW_MODAL_MASTER_TOKO";

export const SHOW_MODAL_DATA_ONGKOS = "SHOW_MODAL_DATA_ONGKOS";
export const GET_PARAMETER_ONGKOS = "GET_PARAMETER_ONGKOS";
export const GET_PLAFOND = "GET_PLAFOND";
export const GET_PARAMETER_KETERANGAN_NOTA = "GET_PARAMETER_KETERANGAN_NOTA";
export const GET_JENIS_GROUP = "GET_JENIS_GROUP";

//Variavle Data Jenis Group
export const SHOW_MODAL_JENIS_GROUP = "SHOW_MODAL_JENIS_GROUP";
export const GET_DATA_JENIS_GROUP = "GET_DATA_JENIS_GROUP";

export const KATEGORI_HARGA = "KATEGORI_HARGA";

export const GET_MASTER_POTONGAN_PENJUALAN = "GET_MASTER_POTONGAN_PENJUALAN";
export const GET_DATA_ULANGTAHUN = "GET_DATA_ULANGTAHUN";
export const GET_BONUS_SALES = "GET_BONUS_SALES";
export const GET_DATA_BUNGA_HUTANG = "GET_DATA_BUNGA_HUTANG";
export const GET_PLAFOND_HUTANG_KADAR = "GET_PLAFOND_HUTANG_KADAR";

export const SET_STOCK_OPNAME_GLOBAL = "SET_STOCK_OPNAME_GLOBAL";

export const GET_KELOMPOK_MASTER_HARGA = "GET_KELOMPOK_MASTER_HARGA";
export const GET_DATA_PENGALIHAN_SALDO = "GET_DATA_PENGALIHAN_SALDO";

export const DATA_TMP = "DATA_TMP";
export const DATA_SERVICE = "DATA_SERVICE";
export const PARA_HUTANG_POKOK = "PARA_HUTANG_POKOK";
export const DATA_GESEK = "DATA_GESEK";
export const PRE_ORDER = "PRE_ORDER";
export const PROSES_OTORISASI = "PROSES_OTORISASI";

export const GET_PO_FINISH = "GET_PO_FINISH";
export const GET_PARAMETER_CICILAN = "GET_PARAMETER_CICILAN";
export const GET_PARAMETER_KUPON = "GET_PARAMETER_KUPON";

export const prosesOtorisasi = (data) => {
  return (dispatch) => {
    let hasil = {
      user_id: data.username,
      password: data.password,
      kategori: "PENJUALAN",
      description: "PENJUALAN DI BAWAH MODAL",
      keterangan:
        data.keterangan === undefined
          ? ""
          : data.keterangan.toUpperCase() || "-",
    };
    postData("authorization", hasil, [], true)
      .then((resposn) => {
        ToastNotification("success", resposn.data.message);
        dispatch(
          FormModal({
            form: "OTORISASI",
            status: false,
          })
        );
        dispatch(hideModal());
      })
      .catch((err) => {
        ToastNotification("info", "Otorisasi Gagal..");
      });
  };
};

export const simpanDataTmp = (data) => {
  return (dispatch) => {
    dispatch({
      type: DATA_TMP,
      payload: {
        data: data,
      },
    });
  };
};
export const getDataPreOrder = (data) => {
  return async (dispatch) => {
    const res = await getDataParams("pre-order", data);
    if (res.data.preorders.length === 0) {
      ToastNotification("info", "Data Tidak Tersedia");
      dispatch(isLoading(false));
      dispatch({
        type: PRE_ORDER,
        payload: {
          data: {
            count: 0,
            preorders: [],
            skip: 1,
            limit: 50,
            page: 1,
          },
        },
      });
      return false;
    }

    dispatch({
      type: PRE_ORDER,
      payload: {
        data: {
          count: res.data.count,
          preorders: res.data.preorders,
          skip: data.skip,
          limit: data.limit,
          page: data.page,
        },
      },
    });
  };
};
export const getParameterCicilan = () => {
  return async (dispatch) => {
    const res = await getDataNoTimeOut("para-cicilan");
    dispatch({
      type: GET_PARAMETER_CICILAN,
      payload: {
        data: res.data,
      },
    });
  };
};
export const getParameterKupon = () => {
  return async (dispatch) => {
    const res = await getDataNoTimeOut("transaksi-kupon");
    dispatch({
      type: GET_PARAMETER_KUPON,
      payload: {
        data: res.data,
      },
    });
  };
};
export const getPoFinish = () => {
  return async (dispatch) => {
    const res = await getDataNoTimeOut("pre-order?status=finish&limit=9999");
    dispatch({
      type: GET_PO_FINISH,
      payload: {
        data: res.data,
      },
    });
  };
};
export const getDataPengalihanSaldo = () => {
  return async (dispatch) => {
    let tgl = {
      tgl_from: getTglSystem(),
      tgl_to: getTglSystem(),
    };
    const res = await postDataNoTImeOut("reportcash/detail", tgl);
    dispatch({
      type: GET_DATA_PENGALIHAN_SALDO,
      payload: {
        data: res.data,
      },
    });
  };
};
export const getDataGesek = () => {
  return async (dispatch) => {
    const res = await getDataNoTimeOut("non-cash/gesek-histories");
    dispatch({
      type: DATA_GESEK,
      payload: {
        data: res.data,
      },
    });
  };
};
export const getDataParameterService = () => {
  return async (dispatch) => {
    const res = await getDataNoTimeOut("para-deskripsi-service");
    dispatch({
      type: DATA_SERVICE,
      payload: {
        data: res.data,
      },
    });
  };
};

export const getParaHutangPokok = () => {
  return async (dispatch) => {
    const res = await getDataNoTimeOut("para-hutang-pokok");
    dispatch({
      type: PARA_HUTANG_POKOK,
      payload: {
        data: res.data,
      },
    });
  };
};
export const getKelompokMasterHarga = () => {
  return async (dispatch) => {
    const res = await getDataNoTimeOut("kelompok-harga");
    dispatch({
      type: GET_KELOMPOK_MASTER_HARGA,
      payload: {
        data: res.data,
      },
    });
  };
};
export const getPlafondHutangKadar = () => {
  return async (dispatch) => {
    const res = await getData("plafond-hutang-kadar");
    dispatch({
      type: GET_PLAFOND_HUTANG_KADAR,
      payload: {
        data: res.data,
      },
    });
  };
};
export const getDataBungaHutang = () => {
  return async (dispatch) => {
    const res = await getData("para-bunga-hutang");
    dispatch({
      type: GET_DATA_BUNGA_HUTANG,
      payload: {
        data: res.data,
      },
    });
  };
};
export const setStockOpnameGlobal = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_STOCK_OPNAME_GLOBAL,
      payload: {
        data: data,
      },
    });
  };
};

export const getBonusSales = () => {
  return async (dispatch) => {
    const res = await getData("para-bonus-sales");
    dispatch({
      type: GET_BONUS_SALES,
      payload: {
        data: res.data,
      },
    });
  };
};

export const getDataUlangTahun = () => {
  return async (dispatch) => {
    const res = await getData("member/find-current-birthday");
    dispatch({
      type: GET_DATA_ULANGTAHUN,
      payload: {
        data: res.data,
      },
    });
  };
};

export const getDataSupplier = () => {
  return async (dispatch) => {
    const res = await getDataNoTimeOut("supplier-barang");
    dispatch({
      type: GET_DATA_SUPPLIER,
      payload: {
        data: res.data,
      },
    });
  };
};
export const getMasterPotonganPenjualan = () => {
  return async (dispatch) => {
    const res = await getData("para-potongan-kategori");
    dispatch({
      type: GET_MASTER_POTONGAN_PENJUALAN,
      payload: {
        data: res.data,
      },
    });
  };
};

export const getKategoriHarga = () => {
  return async (dispatch) => {
    const res = await getDataNoTimeOut("para-system/key/kategori_harga");
    dispatch({
      type: KATEGORI_HARGA,
      payload: {
        data: res.data.value,
      },
    });
  };
};

export const ShowModalJenisGroup = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_JENIS_GROUP,
      payload: {
        data: data,
      },
    });
  };
};

export const getJenisGroup = () => {
  return async (dispatch) => {
    let response = await getDataNoTimeOut("para-jenis-group");
    dispatch({
      type: GET_JENIS_GROUP,
      payload: {
        data: response.data,
      },
    });
  };
};
export const getParameterKeteranganNota = (data) => {
  return (dispatch) => {
    getData("keterangan-nota")
      .then((res) => {
        dispatch({
          type: GET_PARAMETER_KETERANGAN_NOTA,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const showModalDataOngkos = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_DATA_ONGKOS,
      payload: {
        data: data,
      },
    });
  };
};
export const getDataPlafond = (data) => {
  return (dispatch) => {
    getData("plafond-hutang")
      .then((res) => {
        dispatch({
          type: GET_PLAFOND,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getDataOngkos = (data) => {
  return (dispatch) => {
    getData("parameter-ongkos")
      .then((res) => {
        dispatch({
          type: GET_PARAMETER_ONGKOS,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const showModalDataToko = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_MASTER_TOKO,
      payload: {
        data: data,
      },
    });
  };
};
export const getMasterToko = (data) => {
  return (dispatch) => {
    getDataNoTimeOut("tokos")
      .then((res) => {
        dispatch({
          type: GET_MASTER_TOKO,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

//Terima Barang
export const ShowDataModal = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_DATA_MODAL,
      payload: {
        data: data,
      },
    });
  };
};
export const setFocus = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_FOCUS,
      payload: {
        data: data,
      },
    });
  };
};
export const getServicesToday = (data) => {
  return (dispatch) => {
    getDataNoTimeOut("service/chart?type=today")
      .then((res) => {
        dispatch({
          type: GET_SERVICE_TODAY,
          payload: {
            data: res.data.value,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getPesananToday = (data) => {
  return (dispatch) => {
    getDataNoTimeOut("pesanan/chart?type=today")
      .then((res) => {
        dispatch({
          type: GET_PESANAN_TODAY,
          payload: {
            data: res.data.value,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getPembelianToday = (data) => {
  return (dispatch) => {
    getDataNoTimeOut("pembelian/chart?type=today")
      .then((res) => {
        dispatch({
          type: GET_PEMBELIAN_TODAY,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getPenjualanToday = (data) => {
  return (dispatch) => {
    getDataNoTimeOut("penjualan/chart?type=today")
      .then((res) => {
        dispatch({
          type: GET_PENJUALAN_TODAY,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getDataPembelianPerbulan = (data) => {
  return (dispatch) => {
    getDataNoTimeOut("pembelian/chart?type=monthly")
      .then((res) => {
        dispatch({
          type: GET_DATA_PEMBELIAN_PERBULAN,
          payload: {
            data: res.data.value,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getDataPenjualanPerbulan = (data) => {
  return (dispatch) => {
    getDataNoTimeOut("penjualan/chart?type=monthly")
      .then((res) => {
        dispatch({
          type: GET_DATA_PENJUALAN_PERBULAN,
          payload: {
            data: res.data.value,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getDataPolish = (data) => {
  return (dispatch) => {
    getDataNoTimeOut("batu-polish")
      .then((res) => {
        dispatch({
          type: GET_DATA_POLISH,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getJenisBatu = (data) => {
  return (dispatch) => {
    getDataNoTimeOut("parameter-berlian")
      .then((res) => {
        dispatch({
          type: GET_JENIS_BATU,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getDataBanks = (data) => {
  return (dispatch) => {
    getDataNoTimeOut("banks")
      .then((res) => {
        dispatch({
          type: GET_BANK,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getDataParamHrgBeli = (data) => {
  return (dispatch) => {
    getDataNoTimeOut("para-harga-beli")
      .then((res) => {
        dispatch({
          type: GET_PARAM_HARGA_BELI,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getDataRekening = (data) => {
  return (dispatch) => {
    getDataNoTimeOut("rekenings")
      .then((res) => {
        dispatch({
          type: GET_REKENING,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getDataSymetry = (data) => {
  return (dispatch) => {
    getDataNoTimeOut("batu-symmetry")
      .then((res) => {
        dispatch({
          type: GET_DATA_SYEMETRY,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getDataCutgrad = (data) => {
  return (dispatch) => {
    getDataNoTimeOut("batu-cutgrade")
      .then((res) => {
        dispatch({
          type: GET_DATA_CUTGRAD,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getDataFluorencence = (data) => {
  return (dispatch) => {
    getDataNoTimeOut("batu-fluorescence")
      .then((res) => {
        dispatch({
          type: GET_DATA_FLU,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getDataClarity = (data) => {
  return (dispatch) => {
    getDataNoTimeOut("batu-clarity")
      .then((res) => {
        dispatch({
          type: GET_DATA_CLARITY,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getBatuColor = (data) => {
  return (dispatch) => {
    getDataNoTimeOut("batu-color")
      .then((res) => {
        dispatch({
          type: GET_DATA_BATU_COLOR,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getDataShape = (data) => {
  return (dispatch) => {
    getDataNoTimeOut("batu-shape")
      .then((res) => {
        dispatch({
          type: GET_DATA_SHAPE,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const setNo = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_NO,
      payload: {
        data: data,
      },
    });
  };
};
export const isLoading = (data) => {
  return (dispatch) => {
    dispatch({
      type: ISLOADING,
      payload: {
        data: data,
      },
    });
  };
};
export const isEdit = (data) => {
  return (dispatch) => {
    dispatch({
      type: ISEDIT,
      payload: {
        data: data,
      },
    });
  };
};
export const getDataKas = (currentIndex, sizePerPage) => {
  return (dispatch) => {
    getDataNoTimeOut(`cash/paginated?skip=${currentIndex}&limit=${sizePerPage}`)
      .then((res) => {
        dispatch({
          type: GET_DATA_KAS,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {});
  };
};
export const getParameterRugiNota = (data) => {
  return (dispatch) => {
    getData("ruginota/get/all")
      .then((res) => {
        dispatch({
          type: GET_PARAMETER_RUGI_NOTA,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getParameterHargaEmas = (data) => {
  return (dispatch) => {
    getData("group/get/all")
      .then((res) => {
        dispatch({
          type: GET_PARAMETER_HARGA_EMAS,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getDataHadiah = (data) => {
  return (dispatch) => {
    getData("hadiah/get/all")
      .then((res) => {
        dispatch({
          type: GET_DATA_HADIAH,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const settParameterPoint = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_PARAMETER_POINT,
      payload: {
        data: data,
      },
    });
  };
};
export const getParameterPoint = (data) => {
  return (dispatch) => {
    getData("parapoin/get/all")
      .then((res) => {
        dispatch({
          type: GET_PARAMETER_POINT,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

//Function Data Bandrol
export const ShowModalParameterHargaEmas = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_PARAMETER_HARGA_EMAS,
      payload: {
        data: data,
      },
    });
  };
};
export const ShowModalBandrol = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_BANDROL,
      payload: {
        data: data,
      },
    });
  };
};
export const ShowModalParameterPoint = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_PARAMETER_POINT,
      payload: {
        data: data,
      },
    });
  };
};
export const getBandrol = (data) => {
  return (dispatch) => {
    getData("parabandrol/get/all")
      .then((res) => {
        dispatch({
          type: GET_BANDROL,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getParamGetPoinMember = (data) => {
  return (dispatch) => {
    getData("parameter-member-afiliasi")
      .then((res) => {
        dispatch({
          type: GET_PARAM_POINT_MEMBER,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

//Function Data Kadar
export const ShowModalDataKadar = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_KADAR,
      payload: {
        data: data,
      },
    });
  };
};
export const getDataKadar = (data) => {
  return (dispatch) => {
    getData("kadar/get/all")
      .then((res) => {
        dispatch({
          type: GET_DATA_KADAR,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

//Function Data Group
export const ShowModalGroup = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_GROUP,
      payload: {
        data: data,
      },
    });
  };
};
export const GetDataGroup = (data) => {
  return (dispatch) => {
    getData("group/get/all")
      .then((res) => {
        dispatch({
          type: GET_DATA_GROUP,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
//End Data Group

//Function Data Bank
export const getUangBanks = () => {
  return (dispatch) => {
    getData("cash/history-bank")
      .then((res) => {
        // console.log(res);
        dispatch({
          type: GET_UANG_BANK,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
//End Data Bank

//Function Data Provinsi
export const getDataProvinsi = () => {
  return (dispatch) => {
    getData("provinces")
      .then((res) => {
        // console.log(res);
        dispatch({
          type: GET_DATA_PROVINSI,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const editModalProvinsi = (data) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_DATA_PROVINSI,
      payload: {
        data: data,
      },
    });
  };
};

//Function Data Jenis
export const ShowModalJenis = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_JENIS,
      payload: {
        data: data,
      },
    });
  };
};

export const ShowModalKota = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_KOTA,
      payload: {
        data: data,
      },
    });
  };
};

export const ShowModalKecamatan = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_KECAMATAN,
      payload: {
        data: data,
      },
    });
  };
};


export const getJenisBerlian = (data) => {
  return (dispatch) => {
    getData("jenis/get/berlian")
      .then((res) => {
        dispatch({
          type: GET_DATA_JENIS_BERLIAN,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getDataJenis = (data) => {
  return (dispatch) => {
    getData("jenis/get/all")
      .then((res) => {
        dispatch({
          type: GET_DATA_JENIS,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};


export const getDataKadarEod = (data) => {
  return (dispatch) => {
    getData("kadar-eod/get")
      .then((res) => {
        dispatch({
          type: GET_DATA_KADAR_EOD,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getDataKota = (data) => {
  return (dispatch) => {
    getData("kota/get")
      .then((res) => {
        dispatch({
          type: GET_DATA_KOTA,
          payload: {
            data: res.data,
          }
        })
      })
      .catch((err) => {
        console.log(err);
      })
  }
}

export const getDataKecamatan = (data) => {
  return (dispatch) => {
    getData("kecamatan/get")
      .then((res) => {
        dispatch({
          type: GET_DATA_KECAMATAN,
          payload: {
            data: res.data,
          }
        })
      })
      .catch((err) => {
        console.log(err);
      })
  }
}
//End Data Jenis

//Function Data Kategori
export const ShowtModalKategori = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_KATEGORI,
      payload: {
        data: data,
      },
    });
  };
};
//End Data Kategori

//Function Master Kode Transaksi
export const getParamterTransaksi = () => {
  return (dispatch) => {
    getData("paratransaksi/get/all")
      .then((res) => {
        // console.log(res);
        dispatch({
          type: GET_KODE_TRANSAKSI,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const ShowModalKodeTransaksi = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_KODE_TRANSAKSI,
      payload: {
        data: data,
      },
    });
  };
};

//Function Master Gudang
export const ShowModalGudang = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_GUDANG,
      payload: {
        data: data,
      },
    });
  };
};

export const getDataGudang = (data) => {
  return (dispatch) => {
    var urlMasterGudang = "";
    if (data === "master") {
      urlMasterGudang = "gudang/get/all/master";
    } else if (data === "FILTER_DATA_GUDANG") {
      urlMasterGudang = "gudang/get/hancur-barang";
    } else {
      urlMasterGudang = "gudang/get/all";
    }

    getData(urlMasterGudang)
      .then((res) => {
        dispatch({
          type: GET_DATA_GUDANG,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });

    // if (data === "master") {
    //   getData("gudang/get/all/master")
    //     .then((res) => {
    //       dispatch({
    //         type: GET_DATA_GUDANG,
    //         payload: {
    //           data: res.data
    //         }
    //       });
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // } else {
    //   getData("gudang/get/all")
    //     .then((res) => {
    //       dispatch({
    //         type: GET_DATA_GUDANG,
    //         payload: {
    //           data: res.data
    //         }
    //       });
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // }
  };
};

//Function Master Baki
export const ShowModalBaki = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_BAKI,
      payload: {
        data: data,
      },
    });
  };
};
export const getDataBaki = (data) => {
  return (dispatch) => {
    getData("baki/ready")
      .then((res) => {
        dispatch({
          type: GET_DATA_BAKI,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const aktifTglLaporan = (data) => {
  return (dispatch) => {
    let status = true;
    if (aktifTglSebelumnyaByOwner && aktifTglSebelumnyaBySPV) {
      if (
        getUserdata().level === "OWN" ||
        getUserdata().level === "SPV" ||
        getUserdata().level === "SU" ||
        getUserdata().level === "MU"
      ) {
        // setAktifTgl(false)

        status = false;
      }
    } else if (aktifTglSebelumnyaByOwner) {
      if (
        getUserdata().level === "OWN" ||
        getUserdata().level === "SU" ||
        getUserdata().level === "MU"
      ) {
        status = false;
      }
    } else if (aktifTglSebelumnyaBySPV) {
      if (
        getUserdata().level === "SPV" ||
        getUserdata().level === "SU" ||
        getUserdata().level === "MU"
      ) {
        status = false;
      }
    } else {
      status = false;
    }
    dispatch({
      type: AKTIF_TGL,
      payload: status,
    });
  };
};
export const getDataBakiByGudang = (data) => {
  return (dispatch) => {
    getData("baki/get/by-kode-gudang/" + data)
      .then((res) => {
        dispatch({
          type: GET_DATA_BAKI,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

//Function Master Sales
export const getDataSales = (data) => {
  return (dispatch) => {
    getData("sales/get/all")
      .then((res) => {
        dispatch({
          type: GET_DATA_SALES,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const ShowModalSales = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_SALES,
      payload: {
        data: data,
      },
    });
  };
};

//Function Master Sales
export const getDataSupplierBerlian = (data) => {
  return (dispatch) => {
    getData("supplier-berlian")
      .then((res) => {
        dispatch({
          type: GET_DATA_SUPPLIER_BERLIAN,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const ShowModalSupplierBerlian = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_SUPPLIER_BERLIAN,
      payload: {
        data: data,
      },
    });
  };
};
//Function Master Tukang
export const getDataTukang = () => {
  return (dispatch) => {
    getData("tukang/get/all")
      .then((res) => {
        dispatch({
          type: GET_DATA_TUKANG,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const ShowModalTukang = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_TUKANG,
      payload: {
        data: data,
      },
    });
  };
};

//Function Master Kondisi Barang
export const getDataKondisiBarang = () => {
  return (dispatch) => {
    getData("parakondisi/get/all")
      .then((res) => {
        dispatch({
          type: GET_DATA_KONDISI_BARANG,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const ShowModalKondisiBarang = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODALKONDISI_BARANG,
      payload: {
        data: data,
      },
    });
  };
};

//Function Master Kondisi Pembelian
export const getKondisiPembelian = () => {
  return (dispatch) => {
    getData("parabeli/get/all")
      .then((res) => {
        dispatch({
          type: GET_KONDISI_PEMBELIAN,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const ShowModalKondisiPembelian = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODOAL_KONDISI_PEMBELIAN,
      payload: {
        data: data,
      },
    });
  };
};

//Function Master Kondisi Pinjaman
export const getKondisiPinjaman = () => {
  return (dispatch) => {
    getData("parapinjaman/get/all")
      .then((res) => {
        dispatch({
          type: GET_KONDISI_PINJAMAN,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const ShowModalKondisiPinjaman = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_KONDISI_PINJAMAN,
      payload: {
        data: data,
      },
    });
  };
};

//Function Master Data Haduah
export const ShowModalDataHadiah = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_DATA_HADIAH,
      payload: {
        data: data,
      },
    });
  };
};

//Function Master Parameter Rugi Nota
export const ShowModalParameterRugiNota = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_RUGI_NOTA,
      payload: {
        data: data,
      },
    });
  };
};
export const showModalDataMarketPlcae = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_MARKETPLACE,
      payload: {
        data: data,
      },
    });
  };
};
export const getDataMarketplace = () => {
  return (dispatch) => {
    getDataNoTimeOut("marketplace")
      .then((res) => {
        dispatch({
          type: GET_DATA_MARKETPLCAE,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// Modal
export const hideModal = () => {
  return (dispatch) => {
    dispatch({
      type: HIDE_MODAL,
      payload: {
        data: false,
      },
    });
  };
};
export const showModal = () => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL,
      payload: {
        data: true,
      },
    });
  };
};
// End Modal

//
