import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { InputDate } from "../../../components/helpers/field";
import { getTglSystem, removeItem } from "../../../components/helpers/function";
import Tabel from "../../../components/helpers/tabel";
import ValidasiTanggalLaporan from "../../../Validasi/ValidasiTanggalLaporan";
import Loading from "react-fullscreen-loading";
import CabangSelector from "../../../components/pusat/cabang_selector";
import { aktifTglLaporan, getDataUser } from "../../../actions";
import LaporanPesananBarangPdf from "./pdf/LaporanPesananBarangPdf";
import LaporanPesananBarangExel from "./exel/LaporanPesananBarangExel";
class HeadLaporanPesananBarang extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      columns: [
        {
          dataField: "no_pesanan",
          text: "No Pesanan",
          footer: "",
          footerAttrs: {
            colSpan: "4",
          },
        },
        {
          dataField: "kode_sales",
          text: "Sales",
        },
        {
          dataField: "tgl_pesanan",
          text: "Tgl Pesan",
        },
        {
          dataField: "deskripsi",
          text: "Deskripsi",
        },

        {
          dataField: "berat",
          text: "Berat",
          headerClasses: "text-right",
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {columnData.reduce((acc, item) => acc + item, 0).toFixed(2)}{" "}
            </div>
          ),
          formatter: (data) => {
            return <div className="text-right"> {data.toFixed(2)}</div>;
          },
        },

        {
          dataField: "ongkos",
          text: "Ongkos",
          headerClasses: "text-right",

          formatter: (data) => {
            return (
              <div className="text-right"> {data.toLocaleString("kr-ko")}</div>
            );
          },
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {columnData
                .reduce((acc, item) => acc + item, 0)
                .toLocaleString("kr-KO")}{" "}
            </div>
          ),
        },
        {
          dataField: "sub_total",
          text: "Sub Total",
          headerClasses: "text-right",
          formatter: (data) => {
            return (
              <div className="text-right"> {data.toLocaleString("kr-ko")}</div>
            );
          },
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {columnData
                .reduce((acc, item) => acc + item, 0)
                .toLocaleString("kr-KO")}{" "}
            </div>
          ),
        },
      ],
    };
    this.setStartDate = this.setStartDate.bind(this);
    this.setLastDate = this.setLastDate.bind(this);
  }
  componentDidMount() {
    this.props.dispatch(aktifTglLaporan());
    this.props.dispatch(getDataUser());
    this.props.change("valid_by", "ALL");
    this.props.change("status_pesanan", "ALL");
  }
  componentWillUnmount() {
    removeItem("portal1");
    removeItem("portal2");
  }
  exportPdf() {
    LaporanPesananBarangPdf(this.props.LaporanPesanan);
  }
  setStartDate(date) {
    this.setState({
      tgl_awal: new Date(date),
    });
  }
  setLastDate(date) {
    this.setState({
      tgl_akhir: new Date(date),
    });
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit} autoComplete="off">
        <div className="row">
          <div className={this.props.pusat ? "col-lg-4" : "d-none"}>
            <CabangSelector />
          </div>
          <div className="col-lg-4">
            <Field
              name="tgl_awal"
              component={InputDate}
              label="Tanggal Dari"
              type="text"
              readOnly={this.props.aktif_tanggal}
              selected={this.state.tgl_awal}
              onChange={(date) => this.setStartDate(date)}
              placeholder="Masukkan Tanggal Dari"
            />
          </div>

          <div className="col-lg-4">
            <Field
              name="tgl_akhir"
              component={InputDate}
              type="text"
              readOnly={this.props.aktif_tanggal}
              selected={this.state.tgl_akhir}
              onChange={(date) => this.setLastDate(date)}
              label="Tanggal Akhir"
              placeholder="Masukkan Tanggal Akhir"
            />
          </div>

          <div className="col-lg-4">
            <div className="text-right">
              <label>&nbsp;</label>
              <button
                type="submit"
                value="PDF"
                name="type_btn"
                className="btn btn-primary btn-block"
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Mencari
                  </>
                ) : (
                  "Cari Laporan"
                )}
              </button>
            </div>
          </div>
          <div className="col-lg-12 mt-3">
            <Tabel
              keyField="no_pesanan"
              data={this.props.export ? this.props.LaporanPesanan : []}
              columns={this.state.columns}
              empty={true}
              textEmpty="Data Laporan Titip Uang Kosong"
            />
          </div>
          <div className={this.props.export ? "col-lg-12" : "col-lg-12 d-none"}>
            {/* <label htmlFor="">&nbsp;</label> */}
            <div className="row">
              <div className="col-lg-6">
                <button
                  type="button"
                  onClick={() => this.exportPdf()}
                  className="btn btn-warning btn-block"
                >
                  {" "}
                  Export Pdf{" "}
                </button>
              </div>
              <div className="col-lg-6">
                <LaporanPesananBarangExel data={this.props.LaporanPesanan} />
              </div>
            </div>
          </div>
        </div>
        {this.props.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

HeadLaporanPesananBarang = reduxForm({
  form: "HeadLaporanPesananBarang",
  enableReinitialize: true,
  validate: ValidasiTanggalLaporan,
})(HeadLaporanPesananBarang);
// export default connect()(HeadLaporanPesanan);
export default connect((state) => {
  return {
    pusat: state.utility.isPusat,
    aktif_tanggal: state.datamaster.getAktifTgl,
    DataUsers: state.manageusers.getDataUser,
    initialValues: {
      tgl_awal: getTglSystem(),
      tgl_akhir: getTglSystem(),
    },
  };
})(HeadLaporanPesananBarang);
