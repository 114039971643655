import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { getItem, getUserdata } from "../../../../components/helpers/function";
class LaporanPesananBarangExel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-success btn-block"
          table="table-to-xls"
          filename="Laporan Pesanan Barang"
          sheet="Laporan Pesanan Barang"
          buttonText="Export Excel"
        />
        <table id="table-to-xls" style={{ display: "none" }}>
          <thead>
            <tr>
              <th colSpan="7" style={{ textAlign: "center" }}>
                {" "}
                LAPORAN PESANAN BARANG{" "}
              </th>
            </tr>
            <tr>
              <th colSpan="7"> Periode </th>
            </tr>
            <tr>
              <th colSpan="7">
                {" "}
                {getItem("tgl_laporan").length === 0
                  ? null
                  : getItem("tgl_laporan").tgl_from +
                    " s/d " +
                    getItem("tgl_laporan").tgl_to}{" "}
              </th>
            </tr>
            <tr>
              <th colSpan="7"> TOKO : {getUserdata().nama_toko} </th>
            </tr>

            <tr>
              <th colSpan="7"> ALAMAT : {getUserdata().alamat_toko} </th>
            </tr>
            <tr></tr>
            <tr>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                NO PESANAN
              </td>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                SALES
              </td>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                TGL PESAN
              </td>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                DESKRIPSI
              </td>

              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                BERAT
              </td>

              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                ONGKOS
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                SUB TOTAL
              </td>
            </tr>
          </thead>
          <tbody>
            {this.props.data.map((row, index) => (
              <tr key={index}>
                <td>{row.no_pesanan}</td>
                <td>{row.kode_sales}</td>
                <td>{row.tgl_pesanan}</td>
                <td>{row.deskripsi ?? "-"}</td>
                <td style={{ textAlign: "right" }}>{row.berat.toFixed(2)}</td>
                <td style={{ textAlign: "right" }}>
                  {row.ongkos.toLocaleString("kr-KO")}
                </td>
                <td style={{ textAlign: "right" }}>
                  {row.sub_total.toLocaleString("kr-KO")}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td
                colSpan="4"
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "center",
                }}
              >
                Grand Total :
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {" "}
                {this.props.data
                  .map((list) => list.berat)
                  .reduce((a, b) => a + b, 0)
                  .toFixed(2)}{" "}
              </td>

              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {" "}
                {this.props.data
                  .map((list) => list.ongkos)
                  .reduce((a, b) => a + b, 0)
                  .toLocaleString("ko-KO")}{" "}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {" "}
                {this.props.data
                  .map((list) => list.sub_total)
                  .reduce((a, b) => a + b, 0)
                  .toLocaleString("ko-KO")}{" "}
              </td>
            </tr>
          </tfoot>
        </table>
      </>
    );
  }
}
export default LaporanPesananBarangExel;
