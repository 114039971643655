import {
    React,
    Tabel,
    useSelector,
    selectorMaster,
  } from "../../../components/helpers/library";
  
  let InformasiPembayaran = (props) => {
    const DataForm = useSelector(selectorMaster.datatmp);
  
    let columns = [
      {
        dataField: "jenis",
        text: "Jenis",
      },
      {
        dataField:
          DataForm.status === "PENJUALAN" || DataForm.status === "PESANAN"
            ? "jumlah_in"
            : "jumlah_out",
        text: "Total",
        headerClasses: "text-right",
        formatter: (data) => {
          return (
            <div className="text-right"> {data.toLocaleString("kr-ko")} </div>
          );
        },
      },
    ];
  
    return (
      <Tabel
        keyField="jenis"
        exportCsv={false}
        data={DataForm.data?.filter((list) =>
          DataForm.status === "PENJUALAN" ? list.jumlah_in > 0 : true
        )}
        columns={columns}
        empty={true}
        pagination="off"
        textEmpty="Data Pembayaran Kosong"
      />
    );
  };
  
  export default InformasiPembayaran;
  