import {
    disableButtonValidasi,
    saveValidasiBeli,
    saveValidasiJual,
  } from "../../../actions/hutang_action.jsx";
  import {
    ToastNotification,
    React,
    connect,
    getImage,
    change,
    postDataNoTImeOut,
    Panel,
    getDataNoTimeOut,
    PanelBody,
    PanelHeader,
    setItem,
    removeItem,
    getItem,
    reduxForm,
    reset,
    Link,
    NotaSlipGanerate,
    findModule,
    getUserdata,
    getDataParams,
    showModal,
    ModalGlobal,
    isLoading,
    bgputih,
    simpanDataTmp,
    lang,
    ListTokoLaris,
  } from "../../../components/helpers/library";
  import HeadValidasiBeli from "./HeadValidasiBeli.jsx";
  import CetakFakturJualBeli from "./pdf/CetakFakturJualBeli.jsx";
  import notFound from "../../../assets/image-not-found.png";
  import BackButtonPos from "../../../components/pos_property/BackButton";
  import Swal from "sweetalert2";
  import NotaPenjualanBerlianJb from "../../Penjualan/LihatPenjualan/NotaPenjualanBerlian/NotaPenjualanBerlianJb.jsx";
  import InformasiPembayaran from "./InformasiPembayaran.jsx";
  import NotaPenjualanBerlianLrs from "../../../pages/Penjualan/LihatPenjualan/NotaPenjualanBerlian/NotaPenjualanBerlianLrs.jsx";
  import CetakNotaPenjualanBerlianBtb from "../../../pages/Penjualan/LihatPenjualan/NotaPenjualanBerlian/NotaPenjualanBerlianBtb.jsx";
  
  const module = getItem("module") || [];
  let moduleValidasiInputBy = findModule("VALIDASI_INPUT_BY_KEUANGAN_MODULE");
  let reprintTukar = findModule("REPRINT_SLIP_TUKAR");
  
  class FormValidasiBeli extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: false,
        isLoadingBtl: false,
        columnsJual: [
          {
            dataField: "no_faktur_jual",
            text: "No FJ",
            footer: (cell, row, num, index) => {
              return (
                <div>
                  Total Barang :{" "}
                  {getItem("validasi_jual") === null
                    ? 0
                    : getItem("validasi_jual").length || 0}{" "}
                </div>
              );
            },
          },
          {
            dataField: "nama_customer",
            text: "Nama Customer",
            formatter: (cell) => {
              return cell === undefined || cell === null
                ? "-"
                : cell.toUpperCase();
            },
          },
          {
            dataField: "nama_sales",
            text: "Sales",
            formatter: (cell) => {
              return cell === undefined || cell === null
                ? "-"
                : cell.toUpperCase();
            },
          },
          {
            dataField: "kode_barcode",
            text: "Kode Barcode ",
            footer: "",
          },
          {
            dataField: "nama_barang",
            text: "Nama Barang",
            footer: "",
            formatter: (cell) => {
              return cell === undefined || cell === null
                ? "-"
                : cell.toUpperCase();
            },
            footerAttrs: {
              colSpan: "4",
            },
          },
          {
            dataField: "nama_atribut",
            text: "Nama Atr",
          },
  
          {
            dataField: "berat",
            text: "Berat",
            formatter: (cell) => {
              return cell.toFixed(3);
            },
            footer: (columnData) =>
              columnData.reduce((acc, item) => acc + item, 0).toFixed(3),
          },
          {
            dataField: "berat_atribut",
            text: "Berat Atr",
            // formatter: (cell) => {
            //   return cell.toFixed(3);
            // },
            // footer: (columnData) =>
            //   columnData.reduce((acc, item) => acc + item, 0).toFixed(3),
  
            formatter: (cell) => {
              return (
                <div className="text-right"> {Number(cell || 0).toFixed(3)} </div>
              );
            },
            footer: (columnData) => (
              <div className="text-right">
                {" "}
                {columnData
                  .reduce((acc, item) => acc + (item === undefined ? 0 : item), 0)
                  .toFixed(3)}{" "}
              </div>
            ),
          },
          {
            dataField: "harga_total",
            text: "Harga",
            headerClasses: "text-right",
            footer: (columnData) => {
              setItem(
                "total_harga_beli",
                columnData.reduce((acc, item) => acc + item, 0)
              );
              return (
                <div className="text-right">
                  {" "}
                  {columnData
                    .reduce((acc, item) => acc + item, 0)
                    .toLocaleString("kr-KO")}
                </div>
              );
            },
            formatter: (cell) => {
              return (
                <div className="text-right">
                  {parseFloat(cell).toLocaleString("kr-KO")}
                </div>
              );
            },
          },
          {
            dataField: "harga_rata",
            text: "Harga rata",
            headerClasses: "text-right",
            footer: "",
            formatter: (cell, row) => {
              return (
                <div className="text-right">
                  {row.berat === 0
                    ? 0
                    : parseInt(
                        parseInt(row.harga_jual || 0) / parseFloat(row.berat || 1)
                      ).toLocaleString("kr-KO")}
                </div>
              );
            },
          },
          {
            dataField: "ambil",
            text: "Info Pembayaran",
            csvExport: false,
            headerClasses: "text-center",
            formatter: (rowcontent, row, index) => {
              return (
                <div className="text-center">
                  <button
                    type="button"
                    data-tooltip="Informasi Pembayaran"
                    onClick={() => this.cekPembayaran(row, "PENJUALAN")}
                    className="btn btn-primary"
                  >
                    <i className="fa fa-eye"></i>
                  </button>
                </div>
              );
            },
            footer: "",
          },
        ],
        columnsBeli: [
          {
            dataField: "no_faktur_beli",
            text: "No FB ",
            footer: (cell, row, num, index) => {
              return (
                <div>
                  Total Barang :{" "}
                  {getItem("validasi_beli") === null
                    ? 0
                    : getItem("validasi_beli").length || 0}{" "}
                </div>
              );
            },
          },
          {
            dataField: "kode_dept",
            text: "Kode Jenis ",
          },
          {
            dataField: "nama_sales",
            text: "Sales",
            formatter: (cell) => {
              return cell === undefined || cell === null
                ? "-"
                : cell.toUpperCase();
            },
          },
          {
            dataField: "nama_barang",
            text: "Nama Barang",
            footer: "",
            formatter: (cell) => {
              return cell === undefined || cell === null
                ? "-"
                : cell.toUpperCase();
            },
            footerAttrs: {
              colSpan: "4",
            },
          },
          {
            dataField: "kondisi",
            text: "Kondisi",
          },
          {
            dataField: "berat",
            text: "Berat",
            formatter: (cell) => {
              return cell.toFixed(3);
            },
            footer: (columnData) =>
              columnData.reduce((acc, item) => acc + item, 0).toFixed(3),
          },
          {
            dataField: "harga",
            text: "Harga",
            headerClasses: "text-right",
            footer: (columnData) => {
              setItem(
                "total_harga_beli",
                columnData.reduce((acc, item) => acc + item, 0)
              );
              return (
                <div className="text-right">
                  {" "}
                  {columnData
                    .reduce((acc, item) => acc + item, 0)
                    .toLocaleString("kr-KO")}
                </div>
              );
            },
            formatter: (cell) => {
              return (
                <div className="text-right">
                  {parseFloat(cell || 0).toLocaleString("kr-KO")}
                </div>
              );
            },
          },
          {
            dataField: "harga_rata",
            text: "Harga rata",
            headerClasses: "text-right",
            footer: "",
            formatter: (cell, row) => {
              return (
                <div className="text-right">{cell?.toLocaleString("kr-KO")}</div>
              );
            },
          },
          {
            dataField: "ambil",
            text: "Informasi Pembayaran",
            csvExport: false,
            headerClasses: "text-center",
            formatter: (rowcontent, row, index) => {
              return (
                <div className="text-center">
                  <button
                    type="button"
                    data-tooltip="Informasi Pembayaran"
                    onClick={() => this.cekPembayaran(row, "PEMBELIAN")}
                    className="btn btn-primary"
                  >
                    <i className="fa fa-eye"></i>
                  </button>
                </div>
              );
            },
            footer: "",
          },
        ],
      };
  
      this.handleSubmit = this.handleSubmit.bind(this);
      this.btnClear = this.btnClear.bind(this);
      this.batalValidasi = this.batalValidasi.bind(this);
    }
    async cekPembayaran(row, status) {
      this.props.dispatch(isLoading(true));
      let hasil = {
        deskripsi: row.no_faktur_group,
      };
      await getDataParams("validasi/detail/", hasil)
        .then((res) => {
          let hasil = {
            status: status,
            data: res.data,
          };
          this.props.dispatch(simpanDataTmp(hasil));
          this.props.dispatch(isLoading(false));
          this.props.dispatch(showModal());
        })
        .catch((err) => {
          ToastNotification("info", " Terjadi Kesalhaan Saat Mengirim Data");
          this.props.dispatch(isLoading(false));
        });
    }
    getDetaValidasi() {
      this.setState({
        isLoading: true,
      });
      this.props.dispatch(
        change("HeadValidasiBeli", "kode_sales", "ALL")
      );
      getDataNoTimeOut("validasi/all")
        .then((res) => {
          if (
            res.data[0].pembelian.length === 0
          ) {
            console.log("Masuk If")
            ToastNotification("info", "Belum Ada Data Yang Harus Di Validasi");
            this.setState({
              isLoading: false,
            });
            this.props.change("grandtotal", 0);
          } else {
            console.log("Masuk Else")
            ToastNotification("success", "Data Yang Harus Validasi Tersedia");
            let harga = 0;
            res.data[0].pembelian.forEach((element) => {
              harga = harga + element.harga;
            });
  
            this.props.dispatch(disableButtonValidasi(true));
            setItem("validasi_jual", res.data[0].penjualan);
            setItem("validasi_beli", res.data[0].pembelian);
            this.props.dispatch(saveValidasiJual(res.data[0].penjualan));
            this.props.dispatch(saveValidasiBeli(res.data[0].pembelian));
            this.setState({
              isLoading: false,
            });
          }
        })
        .catch((err) => {
          ToastNotification(
            "info",
            err.response?.data || "Terjadi Kesalahan Saat Request Data"
          );
  
          this.props.dispatch(saveValidasiJual([]));
          this.props.dispatch(saveValidasiBeli([]));
          setItem("validasi_jual", []);
          setItem("validasi_beli", []);
          this.setState({
            isLoading: false,
            DataValidasi: [],
          });
        });
    }
    btnClear() {
      this.setState({
        isLoadingBtl: true,
      });
      setTimeout(() => {
        removeItem("validasi_jual");
        removeItem("validasi_beli");
        this.props.dispatch(reset("HeadValidasiJualBeliHutang"));
        this.props.dispatch(saveValidasiJual([]));
        this.props.dispatch(saveValidasiBeli([]));
        this.props.dispatch(disableButtonValidasi(false));
        this.setState({
          isLoadingBtl: false,
        });
      }, 1000);
    }
    batalValidasi() {
      let validasi_jual = getItem("data_validasi_jual") || [];
      let validasi_beli = getItem("data_validasi_beli") || [];
      if (validasi_jual.length === 0 && validasi_beli.length === 0) {
        ToastNotification(
          "info",
          "Data yang akan di batal validasi kosong, PILIH minimal 1 faktur."
        );
        this.setState({
          isLoadingBtl: false,
        });
        return false;
      }
      Swal.fire({
        title: "Anda Yakin !!",
        text: "Ingin Membatal Validasi Ini ?",
        icon: "warning",
        position: "top-center",
        cancelButtonText: lang("Tidak"),
        showCancelButton: true,
        confirmButtonText: lang("Ya"),
        showConfirmButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          let penjualan = [];
          let pembelian = [];
  
          if (validasi_jual.length === 0) {
            penjualan = [];
          } else {
            validasi_jual.forEach((item, index) => {
              let penjualan_tmp = { no_faktur_group: item.no_faktur_group };
              penjualan.push(penjualan_tmp);
            });
          }
  
          if (validasi_beli.length === 0) {
            pembelian = [];
          } else {
            validasi_beli.forEach((item, index) => {
              let pembelian_tmp = { no_faktur_group: item.no_faktur_group };
              pembelian.push(pembelian_tmp);
            });
          }
          let hasil = {
            penjualan: penjualan,
            pembelian: pembelian,
          };
          this.setState({
            isLoadingBtl: true,
          });
          postDataNoTImeOut("validasi/cancel/jualbeli", hasil)
            .then((res) => {
              ToastNotification("success", "Batal Validasi Berhasil");
              this.setState({
                isLoadingBtl: false,
              });
              removeItem("validasi_jual");
              removeItem("validasi_beli");
              removeItem("data_validasi_jual");
              removeItem("data_validasi_beli");
              this.props.dispatch(reset("HeadValidasiJualBeliHutang"));
              this.props.dispatch(saveValidasiJual([]));
              this.props.dispatch(saveValidasiBeli([]));
              this.props.dispatch(disableButtonValidasi(false));
              setTimeout(() => {
                this.getDetaValidasi();
              }, 100);
            })
            .catch((err) => {
              ToastNotification(
                "info",
                err?.response?.data ||
                  "Terjadi Kesalahan Saat Membatal Validasi, Silahkan Ulangi Kembali"
              );
              this.setState({
                isLoadingBtl: false,
              });
            });
        } else {
          this.setState({
            isLoadingBtl: false,
          });
        }
      });
    }
    componentWillUnmount() {
      this.props.dispatch(saveValidasiJual([]));
      this.props.dispatch(saveValidasiBeli([]));
      setItem("validasi_jual", []);
      setItem("validasi_beli", []);
      setItem("data_validasi_jual", []);
      setItem("data_validasi_beli", []);
    }
    componentDidMount() {
      setItem("validasi_jual", []);
      setItem("validasi_beli", []);
      setItem("data_validasi_jual", []);
      setItem("data_validasi_beli", []);
      this.props.dispatch(saveValidasiJual([]));
      this.props.dispatch(saveValidasiBeli([]));
      this.props.dispatch(disableButtonValidasi(false));
    }
    handleSubmit(data) {
      // let moduleValidasiInputBy = findModule("VALIDASI_INPUT_BY_KEUANGAN_MODULE");
  
      // if(moduleValidasiInputBy){X
      //   if(data.user_id === undefined){
      //     ToastNotification('info','User Id Wajib Dipilih')
      //     return false
      //   }
      // }
  
      let validasi_jual = getItem("data_validasi_jual") || [];
      let validasi_beli = getItem("data_validasi_beli") || [];
      if (validasi_jual.length === 0 && validasi_beli.length === 0) {
        ToastNotification(
          "info",
          "Data yang akan divalidasi kosong, PILIH minimal 1 faktur."
        );
        return false;
      }
  
      Swal.fire({
        title: "Grand Total",
        html: `Rp. ${data.grandtotal} <br> ${data.grandtotalBerat} gr`,
        icon: "info",
        position: "top-center",
        cancelButtonText: lang("Tidak"),
        showCancelButton: true,
        confirmButtonText: lang("Ya"),
        showConfirmButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setState({
            isLoading: true,
          });
          let penjualan = [];
          let pembelian = [];
  
          if (validasi_jual.length === 0) {
            penjualan = [];
          } else {
            validasi_jual.forEach((item, index) => {
              let penjualan_tmp = { no_faktur_group: item.no_faktur_group };
              penjualan.push(penjualan_tmp);
            });
          }
  
          if (validasi_beli.length === 0) {
            pembelian = [];
          } else {
            validasi_beli.forEach((item, index) => {
              let pembelian_tmp = { no_faktur_group: item.no_faktur_group };
              pembelian.push(pembelian_tmp);
            });
          }
          let hasil = {
            penjualan: penjualan,
            pembelian: pembelian,
            user_id: moduleValidasiInputBy ? data.user_id : undefined,
          };
  
          // console.log(hasil, "MASUK_SUBMIT");
          // return false
  
          postDataNoTImeOut("validasi/simpan/jualbeli", hasil)
            .then(async (res) => {
              // return false;
              ToastNotification("success", "Validasi Transaksi Berhasil");
              let moduleDuplicateNota = findModule(
                "PRINT_NOTA_JUAL_TWICE_MODULE"
              );
              let jml_nota = moduleDuplicateNota ? 2 : 1;
              let cekPrintNotaBerlian = findModule("PRINT_NOTA_BERLIAN_MODULE");
              let cekPrintNotaSlip = findModule(
                "PRINT_NOTA_JUAL_SLIP_ACC_MODULE"
              );
              let cekPrintPenjualanModule = module.findIndex(
                (list) => list.key === "PENJUALAN_PRINT_MODULE"
              );
              let cekPembelian = module.findIndex(
                (list) => list.key === "PEMBELIAN_PRINT_MODULE"
              );
  
              // console.log(cekPrintPenjualanModule, "MODULE");
              if (cekPrintPenjualanModule === -1) {
                let gambar = [];
                if (reprintTukar) {
                  // console.log(reprintTukar, "AKTIF");
                  if (res.data.dataBarangTukar.length !== 0) {
                    let databaru = {
                      data: res.data.dataBarangTukar,
                      template: res.data.template_tukar[0],
                    };
                    NotaSlipGanerate(
                      databaru,
                      "autoprint_pembelian",
                      "SLIP_TUKAR"
                    );
                  }
                }
                console.log(res, "DATA_VALIDASI_PENJUALAN");
                if (cekPrintNotaBerlian === false && cekPrintNotaSlip === false) {
                  if (res.data.notajual.length !== 0) {
                    const DataFaktur = [];
                    const fakturDuplicate = res.data.notajual
                      .map((cek) => {
                        return jml_nota === 2
                          ? [
                              {
                                Faktur: cek.Faktur.map((faktur) => ({
                                  ...faktur,
                                  barcode: faktur.isi,
                                })),
                              },
                              {
                                Faktur: cek.Faktur.map((faktur) => ({
                                  ...faktur,
                                  barcode: faktur.isi,
                                })),
                              },
                            ]
                          : [
                              {
                                Faktur: cek.Faktur.map((faktur) => ({
                                  ...faktur,
                                  barcode: faktur.isi,
                                })),
                              },
                            ];
                      })
                      .flat();
                    for (const hasil of fakturDuplicate) {
                      const IsiFaktur = [];
                      for (const DetailFaktur of hasil.Faktur) {
                        if (DetailFaktur.jenis === "gambar") {
                          await getImage("foto_produk/" + DetailFaktur.barcode)
                            .then((res) => {
                              DetailFaktur.isi = res;
                            })
                            .catch((err) => {
                              DetailFaktur.isi = notFound;
                            });
                        }
                        IsiFaktur.push(DetailFaktur);
                      }
                      DataFaktur.push({ Faktur: IsiFaktur });
                    }
  
                    await getImage("background_nota/" + getUserdata().kode_toko)
                      .then((res) => {
                        let bg = {
                          background: res,
                        };
                        gambar.push(bg);
                      })
                      .catch((err) => {
                        let bg = {
                          background: bgputih,
                        };
                        gambar.push(bg);
                      });
                    setTimeout(() => {
                      CetakFakturJualBeli(
                        { notajual: DataFaktur },
                        "",
                        "",
                        gambar
                      );
                    }, 200);
                    // CetakFakturJualBeli({ notajual: DataFaktur }, "", "", gambar);
                  }
                } else {
                  if (cekPrintNotaBerlian) {
                    if (res.data.notajualberlian.length !== 0) {
                      if (getUserdata().kode_toko === "JBD") {
                        let datajualberlian = await Promise.all(
                          res.data.datajualberlian.map(async (list) => {
                            let gambar = notFound;
                            await getImage("foto_produk/" + list.kode_barcode)
                              .then((res) => {
                                gambar = res;
                              })
                              .catch((err) => {
                                gambar = notFound;
                              });
                            let hasil = {
                              ...list,
                              gambar,
                            };
                            return hasil;
                          })
                        );
                        NotaPenjualanBerlianJb(datajualberlian);
                      } else if (
                        ListTokoLaris.filter(
                          (list) => list.kode_toko === getUserdata().kode_toko
                        ).length > 0
                      ) {
                        let datajualberlian = await Promise.all(
                          res.data.datajualberlian.map(async (list) => {
                            let gambar = notFound;
                            await getImage("foto_produk/" + list.kode_barcode)
                              .then((res) => {
                                gambar = res;
                              })
                              .catch((err) => {
                                gambar = notFound;
                              });
                            let hasil = {
                              ...list,
                              gambar,
                            };
                            return hasil;
                          })
                        );
  
                        NotaPenjualanBerlianLrs(datajualberlian);
                      } else if (getUserdata().kode_toko === "BTB") {
                        let datajualberlian = await Promise.all(
                          res.data.datajualberlian.map(async (list) => {
                            let gambar = notFound;
                            await getImage("foto_produk/" + list.kode_barcode)
                              .then((res) => {
                                gambar = res;
                              })
                              .catch((err) => {
                                gambar = notFound;
                              });
                            let hasil = {
                              ...list,
                              gambar,
                            };
                            return hasil;
                          })
                        );
                        CetakNotaPenjualanBerlianBtb(datajualberlian);
                      } else {
                        const DataFaktur = [];
                        const fakturDuplicate = res.data.notajualberlian
                          .map((cek) => {
                            return jml_nota === 2
                              ? [
                                  {
                                    Faktur: cek.Faktur.map((faktur) => ({
                                      ...faktur,
                                      barcode: faktur.isi,
                                    })),
                                  },
                                  {
                                    Faktur: cek.Faktur.map((faktur) => ({
                                      ...faktur,
                                      barcode: faktur.isi,
                                    })),
                                  },
                                ]
                              : [
                                  {
                                    Faktur: cek.Faktur.map((faktur) => ({
                                      ...faktur,
                                      barcode: faktur.isi,
                                    })),
                                  },
                                ];
                          })
                          .flat();
                        let gambar = [];
                        for (const hasil of fakturDuplicate) {
                          const IsiFaktur = [];
                          for (const DetailFaktur of hasil.Faktur) {
                            if (DetailFaktur.jenis === "gambar") {
                              await getImage(
                                "foto_produk/" + DetailFaktur.barcode
                              )
                                .then((res) => {
                                  DetailFaktur.isi = res;
                                })
                                .catch((err) => {
                                  DetailFaktur.isi = notFound;
                                });
                            }
                            IsiFaktur.push(DetailFaktur);
                          }
                          DataFaktur.push({ Faktur: IsiFaktur });
                        }
  
                        await getImage(
                          "background_nota/" + getUserdata().kode_toko
                        )
                          .then((res) => {
                            let bg = {
                              background: res,
                            };
                            gambar.push(bg);
                          })
                          .catch((err) => {
                            let bg = {
                              background: bgputih,
                            };
                            gambar.push(bg);
                          });
                        setTimeout(() => {
                          CetakFakturJualBeli(
                            { notajual: DataFaktur },
                            "",
                            "",
                            gambar
                          );
                        }, 200);
                      }
                    }
                  }
                  if (cekPrintNotaSlip) {
                    if (res.data.notajualacc.length !== 0) {
                      let databaru = {
                        data: res.data.notajualacc,
                        template: res.data.templatejualacc,
                      };
                      await NotaSlipGanerate(
                        databaru,
                        "autoprint_pembelian",
                        "SLIP_PENJUALAN_ACC"
                      );
                    }
                  }
                  const notafakturDuplicate =
                    res.data.notajualpesanan.length > 0
                      ? res.data.notajualpesanan
                      : res.data.notajualemas;
  
                  if (notafakturDuplicate.length !== 0) {
                    const DataFaktur = [];
                    const fakturDuplicate = notafakturDuplicate
                      .map((cek) => {
                        return jml_nota === 2
                          ? [
                              {
                                Faktur: cek.Faktur.map((faktur) => ({
                                  ...faktur,
                                  barcode: faktur.isi,
                                })),
                              },
                              {
                                Faktur: cek.Faktur.map((faktur) => ({
                                  ...faktur,
                                  barcode: faktur.isi,
                                })),
                              },
                            ]
                          : [
                              {
                                Faktur: cek.Faktur.map((faktur) => ({
                                  ...faktur,
                                  barcode: faktur.isi,
                                })),
                              },
                            ];
                      })
                      .flat();
                    let gambar = [];
                    for (const hasil of fakturDuplicate) {
                      const IsiFaktur = [];
                      for (const DetailFaktur of hasil.Faktur) {
                        if (DetailFaktur.jenis === "gambar") {
                          await getImage("foto_produk/" + DetailFaktur.barcode)
                            .then((res) => {
                              DetailFaktur.isi = res;
                            })
                            .catch((err) => {
                              DetailFaktur.isi = notFound;
                            });
                        }
                        IsiFaktur.push(DetailFaktur);
                      }
                      DataFaktur.push({ Faktur: IsiFaktur });
                    }
  
                    await getImage("background_nota/" + getUserdata().kode_toko)
                      .then((res) => {
                        let bg = {
                          background: res,
                        };
                        gambar.push(bg);
                      })
                      .catch((err) => {
                        let bg = {
                          background: bgputih,
                        };
                        gambar.push(bg);
                      });
  
                    setTimeout(() => {
                      CetakFakturJualBeli(
                        { notajual: DataFaktur },
                        "",
                        "",
                        gambar
                      );
                    }, 200);
                  }
                }
              }
  
              setTimeout(() => {
                this.getDetaValidasi();
              }, 300);
  
              if (cekPembelian === -1) {
                if (res.data.notabeli.length !== 0) {
                  let databaru = {
                    data: res.data.notabeli,
                    template: res.data.templatebeli,
                  };
                  await NotaSlipGanerate(
                    databaru,
                    "autoprint_pembelian",
                    "SLIP_PEMBELIAN"
                  );
                  setTimeout(() => {
                    this.getDetaValidasi();
                  }, 300);
                }
              }
  
              removeItem("validasi_jual");
              removeItem("validasi_beli");
              removeItem("data_validasi_jual");
              removeItem("data_validasi_beli");
              this.props.dispatch(reset("HeadValidasiJualBeliHutang"));
              this.props.dispatch(saveValidasiJual([]));
              this.props.dispatch(saveValidasiBeli([]));
              this.props.dispatch(disableButtonValidasi(false));
              this.setState({
                isLoadingValidasi: false,
                isLoading: false,
              });
            })
            .catch((err) => {
              console.log(err);
              var hasil = /E-MDB:02/i.test(err?.response?.data);
              if (hasil) {
                window.location.reload();
              }
              this.setState({
                isLoadingValidasi: false,
                isLoading: false,
              });
              ToastNotification(
                "info",
                err?.response?.data || "Terjadi Kesalahaan Saat Mengirim Data"
              );
            });
        }
      });
    }
  
    render() {
      return (
        <div>
          <BackButtonPos link={"/SubMenuValidasi"}>
            <ol className="breadcrumb float-xl-right">
              <li className="breadcrumb-item">
                <Link to="#">Kasir</Link>
              </li>
              <li className="breadcrumb-item active">
                Validasi Pembelian
              </li>
            </ol>
            <h1 className="page-header">Validasi Pembelian </h1>
          </BackButtonPos>
          <Panel>
            <PanelHeader>Validasi Pembelian</PanelHeader>
            <br />
            <PanelBody>
              <HeadValidasiBeli
                onSubmit={(data) => this.handleSubmit(data)}
                isLoading={this.state.isLoading}
                btnClear={() => this.btnClear()}
                batalValidasi={() => this.batalValidasi()}
                isLoadingBtl={this.state.isLoadingBtl}
                columnsBeli={this.state.columnsBeli}
                columnsJual={this.state.columnsJual}
                columnsTitipan={this.state.columnsTitipan}
                columnsPesanan={this.state.columnsPesanan}
              />
            </PanelBody>
            <br />
            <ModalGlobal
              size="P"
              form="DetailPemabayaran"
              title={"Detail Pembayaran"}
              content={<InformasiPembayaran />}
            />
          </Panel>
        </div>
      );
    }
  }
  
  FormValidasiBeli = reduxForm({
    form: "FormValidasiBeli",
    enableReinitialize: true,
  })(FormValidasiBeli);
  export default connect()(FormValidasiBeli);
  