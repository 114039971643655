import jsPDF from "jspdf";
import "jspdf-autotable";
import ImageBckg from "./NotaPenjualanBerlianBtbImage.jpg";
import fontCustom from "./Seravek-Light.ttf";
import seravekBold from "./seravek-bold.ttf";
import moment from "moment-timezone";

const CetakNotaPenjualanBerlianBtb = (data = "") => {
  const doc = new jsPDF("p", "mm", [297, 210]);

  //   doc.setFillColor(240, 240, 240);
  doc.setFontSize(10);
  doc.addFont(fontCustom, "Seravek", "normal");
  doc.addFont(seravekBold, "Seravek-Bold", "bold");
  doc.setFont("Seravek", "normal");

  doc.addImage(
    ImageBckg, // Foto
    "jpg", // Type
    -1, //x
    -1, // y
    150, //Width
    200, //Height
    600 * 0.2645833333,
    490 * 0.2645833333,
    parseInt(0 * -1)
  );

  doc.text("Biling to:", 8, 33);
  doc.text("Invoice No.", 123, 33);
  data.forEach((list, index) => {
    doc.setFont("Seravek-Bold", "bold");
    doc.text(list.nama_customer || "", 8, 37);
    doc.setFont("Seravek", "normal");

    //Alamat
    // let alamat = list.alamat_customer || "-";
    doc.text(list.alamat_customer.slice(0, 40) || "-", 8, 41);
    doc.text(list.no_hp || "", 8, 45);
    // if (alamat.length > 20) {
    //   doc.text(alamat.slice(21, 60), 8, 42);
    // }
    // if (alamat.length > 40 && alamat.length < 80) {
    //   doc.text(alamat.slice(60, 80), 8, 43);
    // }
    // let noFak = list.kode_barcode || "-";

    doc.setFontSize(8);
    doc.setFont(fontCustom, "bold");
    doc.text("#" + list.kode_barcode || "-", 123, 37);

    //Tanggal
    doc.setFont("Seravek", "normal");
    doc.text("Date : ", 113, 41);
    doc.text(
      moment(new Date(list.tgl_system)).format("DD-MM-YYYY") || "-",
      123,
      41
    ); // Formating Tanggal

    console.log(list);
    doc.text("Sales : ", 113, 45);
    doc.text(list?.sales?.nama_sales.slice(0, 9) || "-", 123, 45); //Nama Sales

    doc.setFontSize(8);
    // nama_barang
    doc.text(list.nama_barang, 8, 57);
    doc.text(String(list.qty), 85, 57);
    doc.text(String(list.berat.toFixed(3) + " GR"), 98, 57);
    doc.text("RP " + String(list.harga_jual.toLocaleString("kr-ko")), 120, 57);

    // doc.addImage(list.gambar, 12, 100 + 10, dim + 20, dim + 20);
    doc.addImage(list.gambar, 15, 62, 30, 30, parseInt(180 * -1));

    doc.text("Carat Weight", 8, 100);

    list.data_berlian_list.forEach((el, index) => {
      let jml = index === 0 ? 0 : index === 1 ? 4 : 8;
      doc.text(": " + String(el.jml_batu), 28, 100 + jml);
      doc.text(String(el.kode_shape), 35, 100 + jml);
      doc.text(String(el.karat_batu), 50, 100 + jml);
      doc.text(String("ct"), 60, 100 + jml);
    });

    // console.log(list.data_berlian);

    list.detail_barang.forEach((list2, index) => {
      // console.log(list2.data_berlian[0]);
      doc.text("Colour ", 8, 115);
      doc.text(": " + list2.data_berlian[0]?.["kode_color"], 28, 115);
      doc.text("Clarity", 8, 120);
      doc.text(": " + list2.data_berlian[0]?.["kode_clarity"], 28, 120);
      doc.text("Cut", 8, 125);
      doc.text(
        ": " + (list2?.data_berlian?.[0]?.["kode_cutgrade"] || "-"),
        28,
        125
      );
    });
    doc.setFontSize(10);
    doc.setFont("Seravek-Bold", "bold");
    doc.setTextColor(255, 255, 255);
    doc.text("TOTAL   ", 92, 138);
    doc.text(
      "RP " + String(list.harga_total.toLocaleString("kr-ko")),
      115,
      138
    );
    doc.setFont("Seravek", "normal");
    doc.setTextColor(0, 0, 0);

    doc.setFontSize(7);
    doc.setFont("Seravek-Bold", "bold");
    doc.text("NOTE :", 8, 143);
    doc.setFontSize(8);
    doc.setFont("Seravek", "normal");
    doc.text(
      "1. The Condition of this item is already checked by the customer.",
      8,
      147
    );
    doc.text(
      "2. The authenticity of the diamond stones are examined and certified by Berkah Diamond.",
      8,
      150
    );
    doc.text(
      "3. BuyBack price depends on the condition of the item and also by the period of time.",
      8,
      153
    );

    doc.text(
      "15% price deduction for product exchange, 20% price deduction for sale",
      15,
      156
    );
    doc.text(
      "4. BuyBack price for wedding ring(in stone item or customised-order items):",
      8,
      159
    );
    doc.text(
      "20% price deduction for product exchange, 25% price deduction for sale.",
      15,
      162
    );
    doc.text("5. BuyBack price for defective/damaged item:", 8, 165);
    doc.text(
      "35% price deduction, and the missing diamond stones are changed based on the diamond price.",
      15,
      168
    );
  });

  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    "<html><head><title>Invoice Penjualan</title></head><body style='margin:0 !important'><embed width='100%' height='100%'  src=" +
      string +
      "></embed></body></html>"
  );
};

export default CetakNotaPenjualanBerlianBtb;
