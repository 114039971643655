// import BwipJs from "bwip-js";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment-timezone";

// import dataURL from "../../../../assets/image-not-found.png";

import Tahoma from "./Tahoma Bold.ttf";
import TahomaReguler from "./Tahoma Regular.ttf";
import Candara from "./Candara.ttf";
import TabacGlamG3 from "./TabacGlamG3-Regular.ttf";
import {
  findModule,
  findModuleText,
  generateBarcode,
  generateQRCode,
} from "../../../../components/helpers/function";
import {
  convertDateNota,
  dataURLtoFile,
  bgHitam,
  getUserdata,
  isPos,
  resizeFile,
} from "../../../../components";

let moduleSizeNota = findModuleText("SIZE_NOTA_MODULE");
let cekCopyReprintModule = findModule("COPY_LABEL_REPRINT_MODULE");
let fullPagePrintService = findModule("FULL_PAGE_PRINT_SERVICE_MODULE");
let printNotaPenjualanManualDouble = findModule(
  "NOTA_PENJUALAN_MANUAL_CETAK_COPY"
);

// let jml_nota = printNotaPenjualanManualDouble ? 2 : 1;
const CetakFakturJualBeli = async (
  //
  data = "",
  status = "",
  harga0 = "",
  imgBrgn = ""
) => {
  const file = dataURLtoFile(bgHitam);
  const fotoHitang = await resizeFile(file);
  const doc = new jsPDF();

  // const doc = new jsPDF({
  //   orientation: "landscape",
  //   unit: "mm",
  //   format: moduleSizeNota?.value === "A4" ? "a4" :"a5",
  // });

  doc.setFontSize(15);
  doc.setFontSize(10);
  doc.setProperties({
    title: `CETAK FAKTUR ${data.status || "PENJUALAN"}`,
  });

  let CekModuleisPos = isPos();
  data.notajual.forEach((row, index) => {
    if (imgBrgn.length > 0) {
      // A5
      if (moduleSizeNota?.value === "A4") {
        doc.addImage(imgBrgn[0]["background"], "JPEG", 0, 0, 210, 105); //x,y width, h
      } else {
        doc.addImage(imgBrgn[0]["background"], "JPEG", 0, 0, 210, 148.5);
      }
    }
    if(status === "LIHATTRANSAKSI"){
      if (cekCopyReprintModule) {
        doc.setFontSize(80);
        doc.setTextColor(230, 230, 230); // Warna abu-abu terang untuk watermark
        doc.setFont(TahomaReguler, "Tahoma Regular", "normal");
        doc.text(
          "COPY",
          doc.internal.pageSize.getWidth() / 2, // Posisi horizontal di tengah halaman
          doc.internal.pageSize.getHeight() / 5, // Posisi vertikal di tengah halaman
          { align: "center", angle: 0 } // Rotasi watermark
        );
        doc.setTextColor(0, 0, 0); // Mengembalikan warna teks ke hitam
      }
    }

    let jml_nota = printNotaPenjualanManualDouble ? 2 : 1;
    if (data.status === "SERVICE") {
      if (fullPagePrintService) {
        jml_nota = 3;
      } else {
        jml_nota = 1;
      }
    } else {
      jml_nota =
        data.status === "PENJUALAN MANUAL"
          ? printNotaPenjualanManualDouble
            ? 2
            : 1
          : 1;
    }

    for (let nomer = 0; nomer < jml_nota; nomer++) {
      row.Faktur.forEach((list, index) => {
        const posX = list.position_x * 0.2645833333;
        const posY = list.position_y * 0.2645833333;
        const width = list.width * 0.2645833333;
        const height = list.height * 0.2645833333;
        let angka = 0;
        if (nomer === 0) {
          angka = 0;
        } else if (nomer === 1) {
          // angka = 360;
          angka = printNotaPenjualanManualDouble ? 0 : 360;
        } else {
          angka = 720;
        }
        let isMobile = CekModuleisPos
          ? list.is_mobile === true
          : list.is_mobile !== true;
        if (list.status === true && isMobile) {
          let isMobile = CekModuleisPos
            ? list.is_mobile === true
            : list.is_mobile !== true;
          if (list.status === true && isMobile) {
            if (list.font_family === "tahoma_bold") {
              doc.addFont(Tahoma, "Tahoma Bold", "normal");
              doc.setFont("Tahoma Bold");
            } else if (list.font_family === "tahoma_reguler") {
              doc.addFont(TahomaReguler, "Tahoma Regular", "normal");
              doc.setFont("Tahoma Regular");
            } else if (list.font_family === "candara") {
              doc.addFont(Candara, "Candara", "normal");
              doc.setFont("Candara");
            } else if (list.font_family === "tabacglamg3") {
              doc.addFont(TabacGlamG3, "TabacGlamG3", "Regular");
              doc.setFont("TabacGlamG3");
            } else {
              doc.setFont("times");
              // doc.setFont("Helvetica");
            }

            if (list.jenis === "text") {
              doc.setFontSize(list.font_size || 10);

              if (getUserdata().kode_toko === "A03") {
                if (
                  list.name === "alamat_customer" ||
                  list.name === "no_hp" ||
                  list.name === "nama_customer"
                ) {
                  doc.setFontSize(8);
                } else {
                  doc.setFontSize(list.font_size || 10);
                }
              }
              if (getUserdata().kode_toko === "TPM") {
                if (
                  list.name === "terbilang" ||
                  list.name === "no_faktur_penjualan"
                ) {
                  doc.setFontSize(8);
                } else {
                  doc.setFontSize(list.font_size || 10);
                }
              }
              if (
                list.name === "no_wa_toko" ||
                list.name === "instagram" ||
                list.name === "shopee" ||
                list.name === "tokopedia" ||
                list.name === "no_telpon_toko"
              ) {
                doc.setFontSize(6);
              }
              if (
                getUserdata().kode_toko === "MNI" ||
                getUserdata().kode_toko === "PRB"
              ) {
                doc.setFontSize(8);
              } else {
                doc.setFontSize(list.font_size || 10);
              }

              if (getUserdata().kode_toko === "RJN") {
                if (list.name === "tanggal") {
                  doc.text(
                    list.position_x * 0.2645833333,
                    (parseFloat(list.position_y) + 15 + angka) * 0.2645833333,
                    doc.splitTextToSize(
                      convertDateNota(list.isi),
                      list.width * 0.2645833333
                    ),
                    null,
                    parseInt(list.rotate * -1)
                  );
                } else {
                  doc.text(
                    list.position_x * 0.2645833333,
                    (parseFloat(list.position_y) + 15 + angka) * 0.2645833333,
                    doc.splitTextToSize(list.isi, list.width * 0.2645833333),
                    null,
                    parseInt(list.rotate * -1)
                  );
                }
              } else if (getUserdata().kode_toko === "GS3") {
                if (list.name === "tanggal_system_indonesia") {
                  doc.text(
                    list.position_x * 0.2645833333,
                    (parseFloat(list.position_y) + 15) * 0.2645833333,
                    doc.splitTextToSize(
                      moment(list.isi).format("dd-mm-yyyy"),
                      list.width * 0.2645833333
                    ),
                    null,
                    parseInt(list.rotate * -1)
                  );
                } else {
                  doc.text(
                    list.position_x * 0.2645833333,
                    (parseFloat(list.position_y) + 15 + nomer === 0 ? 20 : 30) *
                      0.2645833333,
                    doc.splitTextToSize(list.isi, list.width * 0.2645833333),
                    null,
                    parseInt(list.rotate * -1)
                  );
                }
              } else {
                if (list?.name === "kode_warna") {
                  doc.setFillColor(
                    list?.isi === "kode warna" ? "#ffffff" : list?.isi
                  );
                  doc.rect(
                    list.position_x * 0.2645833333,
                    (parseFloat(list.position_y) + 15 + angka) * 0.2645833333,
                    20,
                    5,
                    "F"
                  );
                } else if (list?.name === "kode_warna_mobile") {
                  doc.setFillColor(
                    list?.isi === "kode warna" ? "#ffffff" : list?.isi
                  );
                  doc.rect(
                    list.position_x * 0.2645833333,
                    (parseFloat(list.position_y) + 15 + angka) * 0.2645833333,
                    20,
                    5,
                    "F"
                  );
                } else {
                  doc.text(
                    list.position_x * 0.2645833333,
                    (parseFloat(list.position_y) + 15 + angka) * 0.2645833333,
                    doc.splitTextToSize(list.isi, list.width * 0.2645833333),
                    null,
                    parseInt(list.rotate * -1)
                  );
                }
              }
            } else if (list.jenis === "line") {
              doc.line(
                list.position_x * 0.2645833333,
                (parseFloat(list.position_y) + 15 + angka) * 0.2645833333,
                list.position_x * 0.2645833333 + list.width,
                (parseFloat(list.position_y) + 15 + angka) * 0.2645833333
              );
            } else if (list.jenis === "qrcode" || list.jenis === "barcode") {
              let dataURLBarcode = "";
              if (list.jenis === "qrcode") {
                const canvasQRCode = generateQRCode(String(list.isi));
                dataURLBarcode = canvasQRCode.toDataURL("image/JPEG");
              } else {
                const canvasBarcode = generateBarcode(String(list.isi));
                dataURLBarcode = canvasBarcode.toDataURL("image/JPEG");
              }

              doc.addImage(
                dataURLBarcode,
                "JPEG",
                posX,
                posY,
                width,
                height,
                "",
                "MEDIUM",
                Number(list.rotate * -1)
              );
            } else if (list.jenis === "gambar") {
              let cekingfoto = fotoHitang === list.isi ? "" : list.isi;
              if (list.rotate > 0) {
                doc.addImage(
                  cekingfoto,
                  "JPEG" || "JPG",
                  list.position_x * 0.2645833333,
                  parseFloat(list.position_y) * 0.2645833333 -
                    list.width * 0.2645833333,
                  list.width * 0.2645833333,
                  list.height * 0.2645833333,
                  "",
                  "",
                  parseInt(list.rotate * -1)
                );
              } else {
                doc.addImage(
                  cekingfoto,
                  "JPEG",
                  list.position_x * 0.2645833333,
                  parseFloat(list.position_y) * 0.2645833333,
                  list.width * 0.2645833333,
                  list.height * 0.2645833333
                );
              }
            } else {
              // if (list.rotate > 0) {
              //   BwipJs.toCanvas("itf", {
              //     bcid: list.jenis === "qrcode" ? "qrcode" : "code128", // Barcode type
              //     text: String(list.isi), // Text to encode
              //     scale: 3, // 3x scaling factor
              //     height: 10, // Bar height, in millimeters
              //     includetext: false, // Show human-readable text
              //     textxalign: "center", // Always good to set this
              //   });
              //   const img = document.getElementById("itf");
              //   let dataURL = img.toDataURL();
              //   doc.addImage(
              //     dataURL,
              //     "JPEG",
              //     list.position_x * 0.2645833333,
              //     (parseFloat(list.position_y) + angka) * 0.2645833333,
              //     list.width * 0.2645833333,
              //     list.height * 0.2645833333,
              //     600 * 0.2645833333,
              //     490 * 0.2645833333,
              //     parseInt(list.rotate * -1)
              //   );
              // } else {
              //   // BwipJs.toCanvas("itf", {
              //   //   bcid: list.jenis === "qrcode" ? "qrcode" : "code128", // Barcode type
              //   //   text: String(list.isi), // Text to encode
              //   //   scale: 3, // 3x scaling factor
              //   //   height: 10, // Bar height, in millimeters
              //   //   includetext: false, // Show human-readable text
              //   //   textxalign: "center", // Always good to set this
              //   // });
              //   // const img = document.getElementById("itf");
              //   // let dataURL = img.toDataURL();
              //   let dataURL = "";
              //   if (list.jenis === "qrcode") {
              //     const canvasQRCode = generateQRCode(String(list.isi));
              //     dataURL = canvasQRCode.toDataURL("image/png");
              //   } else {
              //     const canvasBarcode = generateBarcode(String(list.isi));
              //     dataURL = canvasBarcode.toDataURL("image/png");
              //   }
              //   doc.addImage(
              //     dataURL,
              //     "JPEG",
              //     list.position_x * 0.2645833333,
              //     (parseFloat(list.position_y) + angka) * 0.2645833333,
              //     list.width * 0.2645833333,
              //     list.height * 0.2645833333,
              //     "",
              //     ""
              //   );
              // }
            }
          }
        }
        // if (status === "LIHATTRANSAKSI") {
        //   if (cekCopyReprintModule) {
        //     doc.setFontSize(60);
        //     doc.setTextColor(230, 230, 230);
        //     doc.text(
        //       "COPY",
        //       300 * 0.2645833333,
        //       400 * 0.2645833333

        //     );
        //     doc.setTextColor(0, 0, 0);
        //     doc.setFontSize(list.font_size || 10);
        //   }
        // }
      });
      if (data.status === "PENJUALAN MANUAL") {
        if (nomer < jml_nota - 1) {
          doc.addPage();
        }
      }

      ////
    }
    if (data.status !== "PENJUALAN MANUAL") {
      if (index < data.notajual.length - 1) {
        doc.addPage();
      }
    }

    // if (nomer < jml_nota  - 1) {
    //   doc.addPage();
  });
  // }
  // doc.text('NPWP 14.10.431.5-5001.000',8.2020833333,154.78125)
  // doc.addImage(dataURL,"JPEG", 14, 25);
  // doc.autoPrint();
  if (data.notajual.length > 10) {
    doc.save(`Cetak Faktur Penjualan.pdf`);
  } else {
    if (
      getUserdata().kode_toko === "GS1" ||
      getUserdata().kode_toko === "GS2" ||
      getUserdata().kode_toko === "GS3" ||
      getUserdata().kode_toko === "NQC"
    ) {
      doc.save(`Cetak Faktur Penjualan.pdf`);
    } else {
      var string = doc.output("datauristring");
      if (isPos()) {
        // eslint-disable-next-line no-undef
        PrintPenjualan.postMessage(string);
        return false;
      }
      doc.autoPrint();
      const blob = doc.output("bloburl");
      window.open(blob);
    }
  }
  // }
  // initialize jsPDF
};
///

export default CetakFakturJualBeli;
