import jsPDF from "jspdf";
import "jspdf-autotable";

import angkaTerbilang from "@develoka/angka-terbilang-js";
import { capitalize } from "../../../../components/helpers/library";

const NotaPenjualanBerlianJb = (data = "", kode_baki = "") => {
  const doc = new jsPDF("p", "mm", [297, 210]);
  let tableRows = [];
  let tableColumn = [];
  let finalY = 40;

  doc.setProperties({
    title: "NOTA PENJUALAN BERLIAN",
  });
  doc.setFontSize(8);
  //Nama Customer
  console.log(data);
  data.forEach((list, index) => {
    let nama = list.nama_customer || "-";
    if (list.alamat_customer.length > 20) {
      doc.text("Customer", 110, 15);
      doc.text(":", 124, 15);
      doc.text(nama?.slice(0, 23), 126, 15);
      //Alamat
      let alamat = list.alamat_customer || "-";
      doc.text("Alamat", 110, 19);
      doc.text(":", 124, 19);
      let judulLaporan = doc.splitTextToSize(alamat, 60);
      doc.text(judulLaporan, 126, 19);
      //No Hp
      doc.text("No Hp", 110, 30);
      doc.text(":", 124, 30);
      doc.text(list.no_hp || "-", 126, 30);
      //Tanggal
      doc.text("Tanggal", 16, 35);
      doc.text(":", 28, 35);
      doc.text(list.tgl_system || "-", 30, 35);
      //No Hp
      doc.text("Invoice No", 110, 35);
      doc.text(":", 124, 35);
      doc.text(list.no_faktur_jual, 126, 35);
    } else {
      doc.text("Customer", 140, 15);
      doc.text(":", 154, 15);
      doc.text(nama?.slice(0, 23), 156, 15);
      //Alamat
      let alamat = list.alamat_customer || "-";
      doc.text("Alamat", 140, 19);
      doc.text(":", 154, 19);
      let judulLaporan = doc.splitTextToSize(alamat, 60);
      doc.text(judulLaporan, 156, 19);
      //No Hp
      doc.text("No Hp", 140, 30);
      doc.text(":", 154, 30);
      doc.text(list.no_hp || "-", 156, 30);
      //Tanggal
      doc.text("Tanggal", 16, 35);
      doc.text(":", 28, 35);
      doc.text(list.tgl_system || "-", 30, 35);
      //No Hp
      doc.text("Invoice No", 140, 35);
      doc.text(":", 154, 35);
      doc.text(list.no_faktur_jual, 156, 35);
    }

    doc.line(16, 40, 186, 40);

    tableColumn = [
      [
        {
          content: `Deskripsi Dan Keterangan Barang`,
        },
        {
          content: `Berat`,
          styles: {
            halign: "center",
          },
        },
        {
          content: `HARGA JUAL (Rp)`,
          styles: {
            halign: "right",
          },
        },
      ],
    ];

    let hasil = [
      {
        content: list.keterangan || "",
      },
      {
        content: list.berat.toFixed(3),
        styles: {
          halign: "center",
        },
      },
      {
        content: list.harga_jual.toLocaleString("kr-ko"),
        styles: {
          halign: "right",
        },
      },
    ];

    tableRows.push(hasil);

    doc.autoTable({
      head: tableColumn,
      body: tableRows,
      tableWidth: 170,
      // foot: footRows,
      didDrawCell: (row) => {
        var posisix = row.cell.x;
        var posisiy = row.cell.y;
        // var dim = row.cell.height - row.cell.padding("vertical");
        if (row.cell.section === "body" && row.column.index === 0) {
          //   doc.text(list.keterangan, posisix, posisiy);
          let databarang = `Satu ${capitalize(
            list.nama_barang.toLowerCase()
          )}. Berat ${list.berat} gram Gold ${list.barang.kadar_cetak}`;
          doc.text(databarang, posisix + 30, finalY + 20);
          doc.text(`${list.data_berlian}`, posisix + 30, finalY + 25);
          doc.text(`${list.data_berlian}`, posisix + 30, finalY + 25);
          doc.text(`${list.keterangan_barang}`, posisix + 30, finalY + 35);

          doc.addImage(list.gambar, posisix, posisiy + 10, 25, 25);
        }
      },
      startY: finalY,
      theme: "plain",
      // rowPageBreak: "avoid",
      // pageBreak: "avoid",
      margin: { top: 10 },
      bodyStyles: {
        // lineWidth: 0.01,
        // lineColor: "#000",
        fontSize: 8,
      },
      headStyles: {
        fontSize: 8,
        //   lineWidth: 0.01,
        lineColor: "#000",
        textColor: "#000",
        //   fillColor: "#E8E5E5",
      },
    });

    finalY = doc.autoTableEndPosY() + 3;
    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.2);
    // doc.line(14, finalY + 40, 188, finalY + 90); //Ini
    // let databarang = `Satu ${capitalize(
    //   list.nama_barang.toLowerCase()
    // )}. Berat ${list.berat} gram Gold ${list.barang.kadar_cetak}`;
    // doc.text(databarang, 16, finalY + 30);
    // doc.text(`${list.data_berlian}`, 16, finalY + 35);

    doc.line(14, finalY + 28, 186, finalY + 28);
    doc.text("Terbilang", 16, finalY + 33);
    doc.text(":", 30, finalY + 33);
    let terbilang = angkaTerbilang(list.harga_jual);
    doc.text(`${capitalize(terbilang)} Rupiah`, 33, finalY + 33);

    doc.text("Total Rp", 150, finalY + 33);
    doc.text(":", 162, finalY + 33);
    doc.text(list.harga_jual.toLocaleString("kr-ko"), 164, finalY + 33);
    let tbl_bawah = [];
    let head = [
      {
        content: `Trade In \n(Tukar Tambah)`,
        styles: {
          // lineWidth: 0.1,
          fontStyle: "italic",
          textColor: "#000",
          halign: "center",
          // fillColor: "#E8E5E5"
        },
      },
      {
        content: `Buy Back \n(Opsi Jual Kembali) `,
        styles: {
          textColor: "#000",
          halign: "center",
          fontStyle: "italic",
        },
      },
    ];
    tbl_bawah.push(head);
    let isi = [
      {
        content: `20%\nOriginal sales amount\nNilai Penjualan yang tertera pada invoice`,
        styles: {
          // lineWidth: 0.1,
          textColor: "#000",
          halign: "center",
          fontStyle: "italic",
          // fillColor: "#E8E5E5"
        },
      },
      {
        content: `25%\nOriginal sales amount\nNilai Penjualan yang tertera pada invoice`,
        styles: {
          textColor: "#000",
          halign: "center",
          fontStyle: "italic",
        },
      },
    ];
    tbl_bawah.push(isi);

    doc.autoTable({
      tableWidth: 170,
      startY: finalY + 35,
      body: tbl_bawah,
      theme: "plain",
      bodyStyles: {
        lineWidth: 0.01,
        fontSize: 8,
      },
    });

    tableRows = [];
    tbl_bawah = [];

    if (index < data.length - 1) {
      doc.addPage();
    }
  });

  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  //   if (data.notajual.length > 10) {
  //     doc.save(`Cetak Faktur Penjualan.pdf`);
  //   } else {
  var string = doc.output("datauristring");

  var x = window.open();
  x.document.open();
  x.document.write(
    "<html><head><title>Cetak Faktur Penjualan Berlian</title></head><body style='margin:0 !important'><embed width='100%' height='100%'  src=" +
      string +
      "></embed></body></html>"
  );
  //   }
};

export default NotaPenjualanBerlianJb;
