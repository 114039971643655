import { getDataSales } from "../../../actions/datamaster_action";
import {
  Component,
  postDataNoTImeOut,
  React,
  Field,
  ReanderSelect,
  connect,
  getItem,
  reduxForm,
  getDataNoTimeOut,
  ToastNotification,
  setItem,
  Tabel,
  Panel,
  PanelHeader,
  Loading,
  findModule,
  getUserdata,
  ReanderField,
  removeDuplicateObject,
  HiidenFiled,
  lang,
} from "../../../components/helpers/library";
import {
  disableButtonValidasi,
  saveValidasiBeli,
  saveValidasiJual,
  saveValidasiPesanan,
  saveValidasiTitipan,
} from "../../../actions/hutang_action";
import { getDataUser } from "../../../actions/mageuser_action";

const maptostate = (state) => {
  return {
    DataUsers: state.manageusers.getDataUser,
    DataSales: state.datamaster.getDataSales,
    saveValidasiBeli: state.hutang.saveValidasiBeli,
    saveValidasiJual: state.hutang.saveValidasiJual,
    saveValidasiTitipan: state.hutang.saveValidasiTitipan,
    saveValidasiPesanan: state.hutang.saveValidasiPesanan,
    btnValidasi: state.hutang.disableButtonValidasi,
  };
};

let moduleValidasiInputBy = findModule("VALIDASI_INPUT_BY_KEUANGAN_MODULE");
let moduleUserFIlter = findModule("VALIDASI_USER_FILTER_MODULE");

class HeadValidasiBeli extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      DataValidasi: [],
      btnValidasi: false,
      kode_sales: false,
      warna: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(getDataUser());
    this.props.dispatch(getDataSales());
    // this.props.dispatch(disableButtonValidasi());
    if (
      this.props.saveValidasiJual.length === 0 &&
      this.props.saveValidasiBeli.length === 0 &&
      this.props.saveValidasiTitipan.length === 0
    ) {
      setItem("validasi_jual", []);
      setItem("validasi_beli", []);
    }
    this.getDetaValidasi();
    this.props.change("kode_sales", "ALL");
    setTimeout(() => {
      this.props.change("user_id", getUserdata().user_id);
    }, 200);
    if (moduleUserFIlter) {
      this.props.change("user_id_filter", "ALL");
    }
  }

  getDetaValidasi() {
    this.setState({
      isLoading: true,
    });

    getDataNoTimeOut("validasi/all")
      .then((res) => {
        if (
          res.data[0].pembelian.length === 0
          // res.data[0].titipan.length === 0 &&
          // res.data[0].pesanan.length === 0
        ) {
          ToastNotification("info", "Belum Ada Data Yang Harus Di Validasi");
          this.setState({
            isLoading: false,
          });
          this.props.change("grandtotal", 0);
        } else {
          ToastNotification("success", "Data Yang Harus Validasi Tersedia");
          let harga_total = 0;
          res.data[0].penjualan.forEach((element) => {
            harga_total = harga_total + element.harga_total;
          });

          let harga = 0;
          res.data[0].pembelian.forEach((element) => {
            harga = harga + element.harga;
          });

          this.setState({
            isLoading: false,
          });
          this.props.dispatch(disableButtonValidasi(true));
          setItem("validasi_jual", res.data[0].penjualan);
          setItem("validasi_beli", res.data[0].pembelian);
          this.props.dispatch(saveValidasiJual(res.data[0].penjualan));
          this.props.dispatch(saveValidasiBeli(res.data[0].pembelian));
        }
      })
      .catch((err) => {
        ToastNotification(
          "info",
          err.response?.data || "Terjadi Kesalahan Saat Request Data"
        );
        this.setState({
          isLoading: false,
          DataValidasi: [],
        });
        this.props.dispatch(saveValidasiJual([]));
        this.props.dispatch(saveValidasiBeli([]));
        this.props.dispatch(saveValidasiTitipan([]));
        this.props.dispatch(saveValidasiPesanan([]));
        setItem("validasi_jual", []);
        setItem("validasi_beli", []);
      });
  }
  cariDataSales(e, status) {
    this.setState({
      loading: true,
    });

    setItem("validasi_jual", []);
    setItem("validasi_beli", []);
    this.setState({
      kode_sales: e,
    });
    if (e === "ALL") {
      this.getDetaValidasi();
      this.setState({
        loading: false,
      });
      return false;
    }
    let hasil = {
      kode_sales: status === "kode_sales" ? e : undefined,
      user_id: status === "user_id" ? e : undefined,
    };
    this.props.dispatch(saveValidasiJual([]));
    this.props.dispatch(saveValidasiBeli([]));
    let ignore = [];
    postDataNoTImeOut("validasi/get/jualbeli", hasil, ignore, true)
      .then((res) => {
        if (
          res.data[0].pembelian.length === 0 &&
          res.data[0].penjualan.length === 0
        ) {
          ToastNotification("info", "Belum ada data yang harus divalidasi");
          this.props.dispatch(saveValidasiJual([]));
          this.props.dispatch(saveValidasiBeli([]));
          setItem("validasi_jual", []);
          setItem("validasi_beli", []);
          this.setState({
            status: true,
          });
          return false;
        }
        setItem("validasi_jual", res.data[0].penjualan);
        setItem("validasi_beli", res.data[0].pembelian);
        this.props.dispatch(saveValidasiJual(res.data[0].penjualan));
        this.props.dispatch(saveValidasiBeli(res.data[0].pembelian));
        this.setState({
          status: true,
        });
        ToastNotification("success", "Data Validasi Tersedia");
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
        ToastNotification("info", "Gagal Mengambil Data Ke server");
      });
  }
  hargaTotal() {
    let penjualan = getItem("data_validasi_jual" || 0);
    let pembelian = getItem("data_validasi_beli" || 0);

    let total =
      penjualan.map((list) => list.harga).reduce((a, b) => a + parseInt(b), 0) -
      pembelian.map((list) => list.harga).reduce((a, b) => a + parseInt(b), 0);
    this.setState({
      warna: total < 0,
    });
    this.props.change("grandtotal", total.toLocaleString("ID-id"));
  }
  render() {
    const module = getItem("module") || [];
    let cekModuleCancelValidation = module.findIndex(
      (list) => list.key === "CANCEL_VALIDATION_MODULE"
    );
    let datasales = [
      {
        value: "ALL",
        name: "SEMUA",
      },
    ];
    this.props.DataSales.forEach((list) => {
      let data = {
        value: list.kode_sales,
        name: list.kode_sales + " - " + list.nama_sales.toUpperCase(),
      };
      datasales.push(data);
    });
    const selectRowPembelian = {
      mode: "checkbox",
      clickToSelect: true,
      selectColumnPosition: "left",
      onSelect: (row, isSelect, rowIndex, e) => {
        let filtered = this.props.saveValidasiBeli.filter(
          (x) => x.no_faktur_group === row.no_faktur_group
        );
        if (isSelect === true) {
          filtered.forEach((x) => {
            let hasil = {
              no_faktur_group: x.no_faktur_group,
              no_faktur: x.no_faktur_beli,
              harga: x.harga,
              berat: x.berat,
            };

            let cek = getItem("data_validasi_beli");
            let hasil_cek = cek.findIndex(
              (hasil) => hasil.no_faktur === x.no_faktur_beli
            );
            if (hasil_cek === -1) {
              cek.push(hasil);
              setItem("data_validasi_beli", cek);
              let penjualan = getItem("data_validasi_jual" || 0);
              let pembelian = getItem("data_validasi_beli" || 0);

              let total =
                penjualan
                  .map((list) => list.harga)
                  .reduce((a, b) => a + parseInt(b), 0) -
                pembelian
                  .map((list) => list.harga)
                  .reduce((a, b) => a + parseInt(b), 0);

              let totBerat =
                penjualan
                  .map((list) => list.berat)
                  .reduce((a, b) => a + parseFloat(b), 0) -
                pembelian
                  .map((list) => list.berat)
                  .reduce((a, b) => a + parseFloat(b), 0);

              this.setState({
                warna: total < 0,
              });
              this.props.change("grandtotal", total.toLocaleString("ID-id"));
              this.props.change(
                "grandtotalBerat",
                totBerat.toLocaleString("ID-id")
              );
            }
          });
        } else {
          filtered.forEach((x) => {
            if (getItem("data_validasi_beli").length === 0) {
            } else {
              let data = getItem("data_validasi_beli");
              const cekIndex = data.findIndex(
                (list1) => list1.no_faktur === x.no_faktur_beli
              );
              data.splice(cekIndex, 1);
              setItem("data_validasi_beli", data);
              let penjualan = getItem("data_validasi_jual" || 0);
              let pembelian = getItem("data_validasi_beli" || 0);
              let total =
                penjualan
                  .map((list) => list.harga)
                  .reduce((a, b) => a + parseInt(b), 0) -
                pembelian
                  .map((list) => list.harga)
                  .reduce((a, b) => a + parseInt(b), 0);

              let totBerat =
                penjualan
                  .map((list) => list.berat)
                  .reduce((a, b) => a + parseFloat(b), 0) -
                pembelian
                  .map((list) => list.berat)
                  .reduce((a, b) => a + parseFloat(b), 0);

              this.setState({
                warna: total < 0,
              });
              this.props.change("grandtotal", total.toLocaleString("ID-id"));
              this.props.change(
                "grandtotalBerat",
                totBerat.toLocaleString("ID-id")
              );
            }
          });
        }
      },
      onSelectAll: (isSelect, data, rowIndex, e) => {
        removeDuplicateObject(data, "no_faktur_group").forEach(
          (list, index) => {
            let filtered = this.props.saveValidasiBeli.filter(
              (x) => x.no_faktur_group === list.no_faktur_group
            );
            if (isSelect === true) {
              filtered.forEach((x) => {
                let hasil = {
                  no_faktur_group: x.no_faktur_group,
                  no_faktur: x.no_faktur_beli,
                  harga: x.harga,
                  berat: x.berat,
                };
                console.log(hasil);
                let cek = getItem("data_validasi_beli");
                let hasil_cek = cek.findIndex(
                  (hasil) => hasil.no_faktur === x.no_faktur_beli
                );
                if (hasil_cek === -1) {
                  cek.push(hasil);
                  setItem("data_validasi_beli", cek);

                  let penjualan = getItem("data_validasi_beli" || 0);
                  let pembelian = getItem("data_validasi_jual" || 0);

                  let total =
                    pembelian
                      .map((list) => list.harga)
                      .reduce((a, b) => a + parseInt(b), 0) -
                    penjualan
                      .map((list) => list.harga)
                      .reduce((a, b) => a + parseInt(b), 0);

                  let totBerat =
                    penjualan
                      .map((list) => list.berat)
                      .reduce((a, b) => a + parseFloat(b), 0) -
                    pembelian
                      .map((list) => list.berat)
                      .reduce((a, b) => a + parseFloat(b), 0);

                  this.setState({
                    warna: total < 0,
                  });
                  console.log(total);
                  this.props.change(
                    "grandtotal",
                    total.toLocaleString("ID-id")
                  );
                  this.props.change(
                    "grandtotalBerat",
                    totBerat.toLocaleString("ID-id")
                  );
                }
              });
            } else {
              if (getItem("data_validasi_beli").length === 0) {
              } else {
                let data = getItem("data_validasi_beli");
                // data.splice(index,1);
                let filtered = this.props.saveValidasiBeli.filter(
                  (x) => x.no_faktur_group === list.no_faktur_group
                );
                filtered.forEach((x) => {
                  const cekIndex = data.findIndex(
                    (list1) => list1.no_faktur_group === x.no_faktur_group
                  );
                  data.splice(cekIndex, 1);
                  setItem("data_validasi_beli", data);
                  let penjualan = getItem("data_validasi_beli" || 0);
                  let pembelian = getItem("data_validasi_jual" || 0);
                  let total =
                    pembelian
                      .map((list) => list.harga)
                      .reduce((a, b) => a + parseInt(b), 0) -
                    penjualan
                      .map((list) => list.harga)
                      .reduce((a, b) => a + parseInt(b), 0);

                  let totBerat =
                    penjualan
                      .map((list) => list.berat)
                      .reduce((a, b) => a + parseFloat(b), 0) -
                    pembelian
                      .map((list) => list.berat)
                      .reduce((a, b) => a + parseFloat(b), 0);

                  this.setState({
                    warna: total < 0,
                  });
                  console.log(total);
                  this.props.change(
                    "grandtotal",
                    total.toLocaleString("ID-id")
                  );
                  this.props.change(
                    "grandtotalBerat",
                    totBerat.toLocaleString("ID-id")
                  );
                });
              }
            }
          }
        );
      },
    };
    let datauserfilter = [
      {
        value: "ALL",
        name: "SEMUA",
      },
    ];
    this.props.DataUsers.filter(
      (cek) =>
        cek.user_id !== undefined &&
        cek.usere_name !== undefined &&
        cek.level !== "SU" &&
        cek.level !== "MU"
    ).forEach((list) => {
      datauserfilter.push({
        value: list.user_id,
        name: list.usere_name,
      });
    });
    return (
      <form
        onSubmit={this.props.handleSubmit}
        autoComplete="off"
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <div className="row">
          {moduleUserFIlter ? (
            <div className="col-3">
              <Field
                name="user_id_filter"
                label="Filter User Id"
                onChange={(e) => this.cariDataSales(e, "user_id")}
                placeholder="Filter User Id"
                options={datauserfilter}
                component={ReanderSelect}
              />
            </div>
          ) : (
            <div className="col-3">
              <Field
                name="kode_sales"
                onChange={(e) => this.cariDataSales(e, "kode_sales")}
                label={`${lang("Pilih")} ${lang("Kode Sales")}`}
                placeholder={`${lang("Pilih")} ${lang("Kode Sales")}`}
                options={datasales}
                component={ReanderSelect}
              />
            </div>
          )}

          {moduleValidasiInputBy && (
            <div className="col-3">
              <Field
                name="user_id"
                label="Pilih User"
                placeholder="Pilih User"
                options={this.props.DataUsers.filter(
                  (cek) =>
                    cek.user_id !== undefined &&
                    cek.usere_name !== undefined &&
                    cek.level === "KASIR"
                ).map((list) => {
                  let row = {
                    value: list.user_id,
                    name: list.usere_name,
                  };
                  return row;
                })}
                component={ReanderSelect}
              />
            </div>
          )}
          <div className="col-12">
            <Panel>
              <PanelHeader>List Data Pembelian</PanelHeader>
              <br />
              <Tabel
                selectRow={selectRowPembelian}
                keyField="no_faktur_group"
                // keyField="_id"
                columns={this.props.columnsBeli}
                data={this.props.saveValidasiBeli || []}
                empty={true}
              />
            </Panel>
          </div>

          <div className="col-6">
            <Field
              name="grandtotal"
              component={ReanderField}
              type="text"
              label="Grand Total"
              placeholder="Grand Total"
              readOnly={true}
              customeCss={
                this.state.warna ? "customeInput" : "customeInputSuccess"
              }
            />
          </div>
          <div className="col-6">
            <Field
              name="grandtotalBerat"
              component={HiidenFiled}
              type="hidden"
              label="Grand Total"
              placeholder="Grand Total"
            />
          </div>

          <div className="col-6">
            <button
              className="btn btn-primary btn-block"
              disabled={this.props.isLoading}
              type="submit"
            >
              {this.props.isLoading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Validasi
                </>
              ) : (
                "Validasi"
              )}
            </button>
            {/* )} */}
          </div>
          <div className="col-6">
            <button
              className="btn btn-warning btn-block"
              disabled={this.props.isLoadingBtl}
              onClick={
                cekModuleCancelValidation === -1
                  ? this.props.btnClear
                  : this.props.batalValidasi
              }
              type="button"
            >
              {this.props.isLoadingBtl ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp;{" "}
                  {cekModuleCancelValidation === -1
                    ? "Batal"
                    : "Batal Validasi"}
                </>
              ) : cekModuleCancelValidation === -1 ? (
                "Batal"
              ) : (
                "Batal Validasi"
              )}
            </button>
          </div>
        </div>
        {this.props.isLoading ||
        this.props.isLoadingBtl ||
        this.state.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

HeadValidasiBeli = reduxForm({
  form: "HeadValidasiBeli",
  enableReinitialize: true,
})(HeadValidasiBeli);
export default connect(maptostate, null)(HeadValidasiBeli);
