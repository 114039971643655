import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  getToday,
  getUserdata,
  getItem,
} from "../../../../components/helpers/function";
const LaporanPesananBarangPdf = (
  data = "",
  username = "",
  tanggal_dari = "",
  tanggal_sampai = ""
) => {
  // initialize jsPDF
  const doc = new jsPDF("l", "mm", [297, 210]);
  let tableRows = [];
  let tableColumn = [];
  let finalY = 30;
  doc.setFontSize(15);
  doc.text("LAPORAN PESANAN BARANG", 215, 15);
  doc.text(getUserdata().nama_toko, 14, 15);
  doc.setFontSize(8);
  let jml_alamat = getUserdata().alamat_toko.length;
  if (jml_alamat > 20) {
    doc.text(getUserdata().alamat_toko.slice(0, 48), 14, 20);
  }
  if (jml_alamat > 50) {
    doc.text(getUserdata().alamat_toko.slice(48, 90), 14, 25);
  }
  doc.setFontSize(10);
  doc.setProperties({
    title: "LAPORAN PESANAN BARANG",
  });
  //row 1
  doc.text(
    `PERIODE : ${
      getItem("tgl_laporan").length === 0
        ? null
        : getItem("tgl_laporan").tgl_from +
          " s/d " +
          getItem("tgl_laporan").tgl_to
    }`,
    215,
    20
  );
  tableColumn = [
    [
      {
        content: `NO PESANAN`,
      },

      {
        content: `SALES`,
      },
      {
        content: `TGL PESAN`,
      },
      {
        content: `DESKRIPSI`,
      },

      {
        content: `BERAT`,
        styles: {
          halign: "right",
        },
      },

      {
        content: `ONGKOS`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `SUB TOTAL`,
        styles: {
          halign: "right",
        },
      },
    ],
  ];
  let sisa_bayar = 0;
  let jumlah_bayar = 0;
  let total_harga = 0;
  let ongkos = 0;
  let total_qty = 0;
  let total_berat = 0;
  data.forEach((item, index) => {
    sisa_bayar = sisa_bayar + item.sisa_bayar;
    jumlah_bayar = jumlah_bayar + item.jumlah_bayar;
    total_harga = total_harga + item.sub_total;
    ongkos = ongkos + item.ongkos;
    total_qty = total_qty + item.total_qty;
    total_berat = total_berat + item.berat;
    let rows = [
      item.no_pesanan,
      item.kode_sales,
      item.tgl_pesanan,
      item.deskripsi ?? "-",
      {
        content: item.berat.toFixed(2),
        styles: {
          halign: "right",
        },
      },

      {
        content: parseInt(item.ongkos).toLocaleString("kr-KO"),
        styles: {
          halign: "right",
        },
      },
      {
        content: parseInt(item.sub_total).toLocaleString("kr-KO"),
        styles: {
          halign: "right",
        },
      },
    ];
    tableRows.push(rows);
  });

  let footer = [
    {
      content: `Grand Total`,
      colSpan: 4,
      styles: {
        halign: "left",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: total_berat.toFixed(2),
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },

    {
      content: parseInt(ongkos).toLocaleString("kr-KO"),
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },

    {
      content: parseInt(total_harga).toLocaleString("kr-KO"),
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
  ];
  tableRows.push(footer);

  let printed = [
    {
      content: `Printed By ${getItem("userdata").user_id} / ${getToday()}`,
      colSpan: 7,
      styles: {
        fontStyle: "italic",
        textColor: "#000",
      },
    },
  ];
  tableRows.push(printed);

  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    startY: finalY,
    theme: "plain",
    rowPageBreak: "avoid",
    margin: { top: 10 },
    footStyles: {
      fontSize: 8,
    },
    bodyStyles: {
      fontSize: 8,
    },
    headStyles: {
      fontSize: 8,
      fillColor: "#E8E5E5",
      textColor: "#000",
    },
    tableLineColor: [255, 255, 255],
  });
  finalY = doc.autoTableEndPosY() + 3;
  tableRows = [];
  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  doc.save(`LAPORAN PESANAN BARANG.pdf`);
};

export default LaporanPesananBarangPdf;
